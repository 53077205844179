import {
  isAllocationGroupState,
  isAllowRegistrationStatus,
} from "../../typechecks";
import { AllocationGroupState, AllowRegistrationStatus } from "../../zod";

export function allowRegistrationParser(
  value: unknown
): AllowRegistrationStatus {
  if (Array.isArray(value)) {
    return allowRegistrationParser(value[0]);
  }
  if (!isAllowRegistrationStatus(value)) {
    return "DURING_INTERVAL";
  }
  return value as AllowRegistrationStatus;
}

export function allocationGroupStateParser(
  value: unknown
): AllocationGroupState {
  if (Array.isArray(value)) {
    return allocationGroupStateParser(value[0]);
  }
  if (!isAllocationGroupState(value)) {
    return "UNKNOWN";
  }
  return value as AllocationGroupState;
}
