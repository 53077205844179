import { ERegion } from "@timeedit/types/lib/enums/region.enum";
import { jwtDecode } from "jwt-decode";
import { ITEToken, regionMapping } from "@timeedit/registration-shared";
import { EAppEnv } from "@timeedit/types/lib/enums/appEnv.enum";
import { logger } from "@timeedit/registration-components";

const regionPlaceHolderInUrl = "|_REGION_|";

export const regionifyUrl = (urlInput: string, region: ERegion) => {
  if (!urlInput.includes(regionPlaceHolderInUrl)) {
    throw new Error(
      `The URL input ${urlInput} does not contain the region placeholder ${regionPlaceHolderInUrl}`
    );
  }

  return urlInput.replace(regionPlaceHolderInUrl, regionMapping[region]);
};

export const getRegionFromAccessToken = (
  accessToken: string | null | undefined
) => {
  if (!accessToken) {
    return null;
  }

  try {
    const token = jwtDecode(accessToken);
    const { region } = ITEToken.parse(token);
    return region;
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export const getRegionalUrl = (environment: EAppEnv) => {
  switch (environment) {
    case EAppEnv.BETA:
      return `https://registration.${regionPlaceHolderInUrl}.timeedit.dev`;
    case EAppEnv.STAGING:
      return `https://registration.${regionPlaceHolderInUrl}.timeedit.io`;
    case EAppEnv.PRODUCTION:
      return `https://registration.${regionPlaceHolderInUrl}.timeedit.net`;
    default:
      throw new Error(
        `Can't get regional Url. Not a valid environment: ${environment}`
      );
  }
};

export const getBaseUrl = (
  region: ERegion | null | undefined,
  environment: EAppEnv
) => {
  if (region && environment !== EAppEnv.DEVELOPMENT) {
    const envBaseUrl = getRegionalUrl(environment);
    return regionifyUrl(envBaseUrl, region);
  }

  return window.location.origin;
};
