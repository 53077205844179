import {
  Registration,
  RegistrationTrack,
  RegistrationTrackList,
  DedicatedTrack,
  exhaustiveMatchingGuard,
  isDefined,
  RegistrationMode,
} from "@timeedit/registration-shared";
import { AllocationControlsProps } from "../../../AllocationControls";

/**
 *
 * @param tracks Tracks to check
 * @returns If all tracks are open it is true, if just one is not open it is false
 */
export function isOpen(tracks: RegistrationTrack[]): boolean {
  if (tracks.length === 0) return false;
  return tracks.every((track) => track.open ?? false);
}

type IsFullProps = {
  tracks: RegistrationTrack[];
  mode: RegistrationMode;
};
/**
 *
 * @param mode Which mode the user is in
 * @param tracks Tracks to check
 * @returns If any track is full the function returns true, otherwise false
 */
export function isFull({ mode, tracks }: IsFullProps): boolean {
  return tracks.some((track) =>
    mode === "student"
      ? track.seats.available === 0
      : track.seats.raw.total - track.seats.raw.taken <= 0
  );
}
type GetAllocationObjectIdsProps = {
  registration: Registration;
  trackList: RegistrationTrackList;
};
/**
 *
 * @param registration Registration object
 * @param trackList TrackList object
 * @returns The objectids which the student is allocated to
 */
export function getAllocationObjectIds({
  registration,
  trackList,
}: GetAllocationObjectIdsProps) {
  return trackList.children.filter((id) => {
    const allocationStatus = registration.tracks?.[id]?.allocationStatus;
    return (
      allocationStatus === "ALLOCATED_TO_THIS" ||
      allocationStatus === "MULTIPLE_ALLOCATIONS"
    );
  });
}

/**
 *
 * @param tracks Tracks to check
 * @returns Combined allocation status of tracks.
 * Return following in priority order:
 * 1: If any track is not allocated, the status is "NOT_ALLOCATED".
 * 2: If any track has multiple allocations the status is "MULTIPLE_ALLOCATIONS".
 * 3: If any track is allocated to another track the status is "ALLOCATED_TO_OTHER".
 * 4: If all tracks are allocated the status is "ALLOCATED_TO_THIS".
 */
export function getAllocationStatus(
  tracks: RegistrationTrack[]
): AllocationControlsProps["allocationStatus"] {
  return tracks.reduce(
    (
      allocationStatus: AllocationControlsProps["allocationStatus"],
      track,
      index
    ) => {
      if (index === 0) return track.allocationStatus;

      if (
        track.allocationStatus === "NOT_ALLOCATED" ||
        allocationStatus === "NOT_ALLOCATED"
      )
        return "NOT_ALLOCATED";

      if (
        track.allocationStatus === "MULTIPLE_ALLOCATIONS" ||
        allocationStatus === "MULTIPLE_ALLOCATIONS"
      )
        return "MULTIPLE_ALLOCATIONS";

      if (
        track.allocationStatus === "ALLOCATED_TO_OTHER" ||
        allocationStatus === "ALLOCATED_TO_OTHER"
      )
        return "ALLOCATED_TO_OTHER";

      return "ALLOCATED_TO_THIS";
    },
    "NOT_ALLOCATED"
  );
}

type DedicationData = {
  relation: Extract<DedicatedTrack, { kind: "relation" }>["data"];
  category: Extract<DedicatedTrack, { kind: "category" }>["data"];
};

/**
 *
 * @param dedicated Dedicated track information
 * @returns Extracts the dedication data and accumulate it to relation and category array
 */
export function getDedicationData(dedicated: DedicatedTrack[]) {
  return dedicated.reduce<DedicationData>(
    (dedicationData: DedicationData, { kind, data }) => {
      switch (kind) {
        case "category": {
          const category = dedicationData.category;
          if (isDefined(category)) {
            return { ...dedicationData, category: [...category, ...data] };
          }
          return dedicationData;
        }
        case "relation": {
          const relation = dedicationData.relation;
          if (isDefined(relation)) {
            return { ...dedicationData, relation: [...relation, ...data] };
          }
          return dedicationData;
        }
        case "none": {
          return dedicationData;
        }
        default: {
          return exhaustiveMatchingGuard(kind);
        }
      }
    },
    { category: [], relation: [] }
  );
}
