import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITEToken } from "@timeedit/registration-shared";

interface UserState {
  loginData?: ITEToken;
  isLoading: boolean;
}

const initialState: UserState = {
  loginData: undefined,
  isLoading: true,
};

const loginDataSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginData(_, action: PayloadAction<ITEToken | undefined>) {
      return { isLoading: false, loginData: action.payload };
    },
  },
});

export const { setLoginData } = loginDataSlice.actions;
export const loginDataReducer = loginDataSlice.reducer;
