import { OfKind } from "../../types";
import {
  allowRegistrationParser,
  booleanParser,
  bufferParser,
  dedicatedTrackParser,
  nonNegativeFiniteIntegerParser,
  integerArrayParser,
  stringParser,
  allocationGroupStateParser,
  nonEmptyStringParser,
} from "../parsers";
import { config } from "./config";

type Shorthand = keyof OfKind<"field", typeof config>;

export const parsers = {
  activityField: stringParser,
  activityType: nonEmptyStringParser,
  allowRegistration: allowRegistrationParser,
  autoAllocation: stringParser,
  buffer: bufferParser,
  changeRequestName: stringParser,
  courseLabel: nonEmptyStringParser,
  courseTitle: nonEmptyStringParser,
  courseRegistrationPeriodSet: booleanParser,
  courseSeats: nonNegativeFiniteIntegerParser,
  dedicatedTrack: dedicatedTrackParser,
  endDate: stringParser,
  expectedEnrollment: stringParser,
  hideTrack: booleanParser,
  linkedTrack: stringParser,
  maxStudents: nonNegativeFiniteIntegerParser,
  programName: stringParser,
  roomName: stringParser,
  roomSize: nonNegativeFiniteIntegerParser,
  semester: stringParser,
  startDate: stringParser,
  state: allocationGroupStateParser,
  studentName: nonEmptyStringParser,
  trackAutoAllocationActivated: booleanParser,
  trackDistributionRule: stringParser,
  trackName: stringParser,
  trackNumber: nonEmptyStringParser,
  waitlist: integerArrayParser,
} satisfies Record<Shorthand, (value: unknown) => unknown>;
