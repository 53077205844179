import {
  EventWrapperProps as RBCEventWrapperProps,
  Views,
} from "react-big-calendar";
import { WeekEvent } from "./WeekEvent";
import { getGraphicalView } from "../RegistrationCalendar";
import cn from "classnames";
import { ReactElement, ReactNode } from "react";
import { SelectOptions } from "../../utils";
import { CalendarEvent } from "../../../../utils/events";

export interface EventWrapperProps extends RBCEventWrapperProps<CalendarEvent> {
  children: ReactElement<EventWrapperChildProps>;
  viewOption: SelectOptions;
}
interface EventWrapperChildProps {
  children: ReactElement<EventDivChildProps>;
  className: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  onDoubleClick: (e: React.MouseEvent<HTMLElement>) => void;
  onKeyPress: (e: React.MouseEvent<HTMLElement>) => void;
  style?: (React.CSSProperties & { xOffset: number }) | undefined;
}
interface EventDivChildProps {
  children: ReactNode;
  className: string;
}
export function EventWrapper(props: EventWrapperProps) {
  const { event, onClick, viewOption } = props;
  const view = getGraphicalView(viewOption);
  const className = cn(props.children.props.className, "rbc-day-slot");
  const style = props.children.props.style;
  if (view === Views.WORK_WEEK || view === Views.WEEK) {
    return WeekEvent({
      onClick,
      style,
      event,
      className,
    });
  }
  return <div>404</div>;
}
