import {
  isBoolean,
  isDedicatedCategory,
  isDedicatedRelation,
  isNullish,
  isNumber,
  isRecord,
  isString,
} from "../../typechecks";
import {
  DedicatedCategory,
  DedicatedRelation,
  DedicatedTrack,
} from "../../zod";

export function nonEmptyStringParser(value: unknown): string {
  const result = stringParser(value);
  return result.length > 0 ? result : "---";
}

export function stringParser(value: unknown): string {
  if (isString(value)) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map((v) => stringParser(v)).join(" ");
  }
  if (isRecord(value) || isNullish(value)) {
    return "";
  }
  if (isNumber(value) || isBoolean(value)) {
    return value.toString();
  }
  return String(value);
}

function createNumberParser(callback: (v: string | number) => number) {
  return (value: unknown): number => {
    if (isNumber(value) && !Number.isNaN(value)) {
      return callback(value);
    }
    if (Array.isArray(value)) {
      return createNumberParser(callback)(value[0]);
    }
    if (isRecord(value) || isNullish(value)) {
      return 0;
    }
    if (isString(value)) {
      if (value === "") {
        return 0;
      }
      const num = callback(value);
      if (!Number.isNaN(num)) {
        return num;
      }
      return 0;
    }
    if (isBoolean(value)) {
      return value ? 1 : 0;
    }
    return callback(Number(value));
  };
}

function createFiniteNumberParser(callback: (v: string | number) => number) {
  return (value: unknown): number => {
    const number = createNumberParser(callback)(value);
    if (Number.isFinite(number)) {
      return number;
    }
    return 0;
  };
}

function createNonNegativeFiniteNumberParser(
  callback: (v: string | number) => number
) {
  return (value: unknown): number => {
    const number = createFiniteNumberParser(callback)(value);
    if (number >= 0) {
      return number;
    }
    return 0;
  };
}

function createNumberArrayParser(callback: (v: string | number) => number) {
  return (value: unknown): number[] => {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return [];
      }
      return value.map((v) => createNumberParser(callback)(v));
    }

    if (
      isRecord(value) ||
      isNullish(value) ||
      isBoolean(value) ||
      value === ""
    ) {
      return [];
    }

    return [createNumberParser(callback)(value)];
  };
}

function integerTransform(v: string | number) {
  if (isNumber(v)) {
    return Math.round(v);
  }
  return parseInt(v, 10);
}

export const numberParser = createNumberParser(Number);
export const finiteNumberParser = createFiniteNumberParser(Number);
export const nonNegativeFiniteNumberParser =
  createNonNegativeFiniteNumberParser(Number);
export const numberArrayParser = createNumberArrayParser(Number);

export const integerParser = createNumberParser(integerTransform);
export const finiteIntegerParser = createFiniteNumberParser(integerTransform);
export const nonNegativeFiniteIntegerParser =
  createNonNegativeFiniteNumberParser(integerTransform);
export const integerArrayParser = createNumberArrayParser(integerTransform);

export function booleanParser(value: unknown): boolean {
  if (isBoolean(value)) {
    return value;
  }
  if (isNumber(value)) {
    return value === 1;
  }
  const stringValue = stringParser(value);
  return /^(true|on|1)$/i.test(stringValue);
}

export function dedicatedTrackParser(value: unknown): DedicatedTrack {
  const stringValue = stringParser(value);

  try {
    const jsonDedicatedTrack = JSON.parse(stringValue);
    const dedicatedTrack: DedicatedTrack = jsonDedicatedTrack;

    if (dedicatedTrack.kind === "category") {
      const category = dedicatedTrack.data.reduce(
        (category: DedicatedCategory[], currentCat) =>
          isDedicatedCategory(currentCat)
            ? [...category, currentCat]
            : category,
        []
      );
      if (category.length === 0) return { kind: "none", data: undefined };

      return { kind: "category", data: category };
    }
    if (dedicatedTrack.kind === "relation") {
      const relation = dedicatedTrack.data.reduce(
        (relation: DedicatedRelation[], currentRel) =>
          isDedicatedRelation(currentRel)
            ? [...relation, currentRel]
            : relation,

        []
      );

      if (relation.length === 0) return { kind: "none", data: undefined };

      return { kind: "relation", data: relation };
    }
    return { kind: "none", data: undefined };
  } catch (e) {
    return { kind: "none", data: undefined };
  }
}
