/* eslint-disable no-console */
import { Logger, LogService } from "@timeedit/registration-shared";

const clientLogger: Logger = {
  error(arg, ...rest) {
    if (["test", "development"].includes(import.meta.env.MODE)) {
      console.error(arg, ...rest);
    }
  },

  warn(arg, ...rest) {
    if (["test", "development"].includes(import.meta.env.MODE)) {
      console.warn(arg, ...rest);
    }
  },

  info(arg, ...rest) {
    if (["test", "development"].includes(import.meta.env.MODE)) {
      console.info(arg, ...rest);
    }
  },

  debug(arg, ...rest) {
    if (["test", "development"].includes(import.meta.env.MODE)) {
      console.debug(arg, ...rest);
    }
  },
};

const logger = new LogService(clientLogger);

export default logger;
