import { isDefined } from "../typechecks";

export function convertToString(name: unknown) {
  return isDefined(name) ? `${name}` : "";
}

export function stringHasContent(string: string | undefined) {
  return !isEmptyString(string);
}

export function isEmptyString(string: string | undefined) {
  if (!isDefined(string)) return false;
  return string.length === 0;
}

export function toTitleCase(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.substring(1).toLowerCase()}`;
}

export function presentText(text: string, max?: number) {
  const maxLength = isDefined(max) ? max : 40;
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
}
