import { en } from "./en";
import { sv } from "./sv";
import { z } from "zod";

const languageCodes = ["en", "sv"] as const;
const LanguageCode = z.enum(languageCodes);
export type LanguageCode = z.infer<typeof LanguageCode>;

export const languageTranslations: Record<LanguageCode, typeof en> = {
  en,
  sv,
};

const defaultLanguage = "en";
export let translations = languageTranslations[defaultLanguage];
export type TranslationType = typeof translations;

interface GetTranslations {
  currentLanguage: LanguageCode;
  translations: TranslationType;
}

export function getTranslations(language?: string): GetTranslations {
  const validLanguage = LanguageCode.safeParse(language);

  if (validLanguage.success) {
    translations = languageTranslations[validLanguage.data];
    return { translations, currentLanguage: validLanguage.data };
  }

  translations = languageTranslations[defaultLanguage];
  return { translations, currentLanguage: defaultLanguage };
}
