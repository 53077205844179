import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../baseQuery";

export const cacheApiSlice = createApi({
  reducerPath: "cacheApi",
  baseQuery,
  endpoints(builder) {
    return {
      fetchClearCache: builder.mutation<null, void>({
        query() {
          return {
            url: "/clear-cache",
            method: "POST",
          };
        },
      }),
      fetchPopulateUserObjects: builder.mutation<null, void>({
        query() {
          return {
            url: "/populate-user-objects",
            method: "POST",
          };
        },
      }),
    };
  },
});

export const {
  useFetchPopulateUserObjectsMutation,
  useFetchClearCacheMutation,
} = cacheApiSlice;
