import { useEffect, useRef, useState } from "react";
import { desktopWidth } from "../../assets/styles/variables";

// eslint-disable-next-line import/no-unused-modules
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState<boolean>(
    window.innerWidth >= desktopWidth
  );

  const checkIsDesktop = () => {
    setIsDesktop(window.innerWidth >= desktopWidth);
  };

  useEffect(() => {
    checkIsDesktop();

    const mediaQuery = window.matchMedia(`(min-width: ${desktopWidth}px)`);

    const handleResize = (event: MediaQueryList) => {
      setIsDesktop(event.matches);
    };

    mediaQuery.addEventListener(
      "change",
      handleResize as unknown as EventListener
    );

    return () => {
      mediaQuery.removeEventListener(
        "change",
        handleResize as unknown as EventListener
      );
    };
  }, []);

  return isDesktop;
}
