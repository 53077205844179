import { Typography } from "antd";
import { TextProps as AntdTextProps } from "antd/lib/typography/Text";

const { Text: AntdText } = Typography;

type TextProps = AntdTextProps;

export function Text(props: TextProps) {
  return <AntdText {...props}>{props.children}</AntdText>;
}
