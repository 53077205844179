interface ConsoleLogger {
  info(arg: unknown, ...rest: unknown[]): void;
  warn(arg: unknown, ...rest: unknown[]): void;
  debug(arg: unknown, ...rest: unknown[]): void;
  error(arg: unknown, ...rest: unknown[]): void;
}

interface WinstonLogger extends ConsoleLogger {
  http(arg: unknown, ...rest: unknown[]): void;
  verbose(arg: unknown, ...rest: unknown[]): void;
  silly(arg: unknown, ...rest: unknown[]): void;
}

export type Logger = ConsoleLogger | WinstonLogger;

/**
 * A logger wrapper that encapsulates the logger implementation. This allows
 * us to use a different logger implementation in different environments.
 */
export class LogService {
  logger: Logger;
  constructor(logger: Logger) {
    this.logger = logger;
  }

  /**
   * Logs an info message.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.info
   */
  info(arg: unknown, ...rest: unknown[]) {
    this.logger.info(arg, ...rest);
  }

  /**
   * Logs a warning message.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.debug
   */
  warn(arg: unknown, ...rest: unknown[]) {
    this.logger.warn(arg, ...rest);
  }

  /**
   * Logs a debug message. Used for low level debug information.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.debug
   */
  debug(arg: unknown, ...rest: unknown[]) {
    this.logger.debug(arg, ...rest);
  }

  /**
   * Logs a error message. Used for when something goes wrong.
   * @param args - One or many arguments of any type. Same signature as the
   * arguments for console.error
   */
  error(arg: unknown, ...rest: unknown[]) {
    this.logger.error(arg, ...rest);
  }

  http(arg: unknown, ...rest: unknown[]) {
    if ("http" in this.logger) {
      this.logger.http(arg, ...rest);
    }
  }

  verbose(arg: unknown, ...rest: unknown[]) {
    if ("verbose" in this.logger) {
      this.logger.verbose(arg, ...rest);
    }
  }

  silly(arg: unknown, ...rest: unknown[]) {
    if ("silly" in this.logger) {
      this.logger.silly(arg, ...rest);
    }
  }

  /**
   * Creates an error function with a base message.
   * @param baseMessage - The base message
   * @returns
   * Function that logs info messages
   */
  createInfoFn(baseMessage: string) {
    return (arg: unknown, ...rest: unknown[]) =>
      this.logger.info(`${baseMessage} ${arg}`, ...rest);
  }

  /**
   * Creates an error function with a base message.
   * @param baseMessage - The base message
   * @returns
   * Function that logs debug messages
   */
  createDebugFn(baseMessage: string) {
    return (arg: unknown, ...rest: unknown[]) =>
      this.logger.debug(`${baseMessage} ${arg}`, ...rest);
  }

  /**
   * Creates an error function with a base message.
   * @param baseMessage - The base message
   * @returns
   * Function that logs errors
   */
  createErrorFn(baseMessage: string) {
    return (arg: unknown, ...rest: unknown[]) =>
      this.logger.error(`${baseMessage} ${arg}`, ...rest);
  }
}
