import { PropsWithChildren, useEffect } from "react";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import enGB from "antd/locale/en_GB";
import svSE from "antd/locale/sv_SE";
import "dayjs/locale/en-gb";
import "dayjs/locale/sv";
import { Locale } from "antd/es/locale";
import { useAppDispatch } from "../redux/hooks";
import { setLocale } from "../redux/slices/lang";
import { LanguageCode } from "@timeedit/registration-shared";

interface LocaleWrapper extends PropsWithChildren {
  localeId: LanguageCode;
}

export const locales: Record<LanguageCode, Locale> = {
  en: enGB,
  sv: svSE,
};

export function LocaleWrapper({ children, localeId }: LocaleWrapper) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLocale({ locale: locales[localeId] }));
  }, [dispatch, localeId]);

  const locale = locales[localeId];
  dayjs.locale(locale.locale);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
}
