import { isDefined, isRecord, isString } from "@timeedit/registration-shared";
import { To } from "react-router-dom";

interface GetToProps {
  to?: To;
  currentLanguage?: string;
  organizationId?: string | null;
}

export function getTo({ to, currentLanguage, organizationId }: GetToProps) {
  if (isString(to)) {
    to = getPathname({ pathname: to, currentLanguage, organizationId });
  }
  if (isRecord(to) && isDefined(to.pathname)) {
    to = {
      ...to,
      pathname: getPathname({
        pathname: to.pathname,
        currentLanguage,
        organizationId,
      }),
    };
  }

  return to;
}

interface GetPathnameProps {
  pathname?: string;
  currentLanguage?: string;
  organizationId?: string | null;
}

function getPathname({
  pathname,
  currentLanguage,
  organizationId,
}: GetPathnameProps) {
  if (pathname === undefined) return "";

  // Only prefix pathname if it's has a / in front of it, otherwise treat
  // it as a relative path.
  if (/^\//.test(pathname)) {
    return `${currentLanguage === undefined ? "" : `/${currentLanguage}`}${
      organizationId === undefined ? "" : `/${organizationId}`
    }${pathname}`;
  }

  return pathname;
}
