import {
  Registration,
  DeallocateRequest,
  SaveStudentsResult,
  SwapRequest,
} from "@timeedit/registration-shared";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../baseQuery";

export const registrationApiSlice = createApi({
  reducerPath: "registrationApi",
  baseQuery,
  tagTypes: ["Registration"],
  endpoints(builder) {
    return {
      swap: builder.mutation<SaveStudentsResult, SwapRequest>({
        query(body) {
          return {
            url: "/swap-members",
            method: "POST",
            body,
            header: {
              "Content-type": "application/json; charset=UTF-8",
            },
          };
        },
        invalidatesTags: ["Registration"],
      }),
      deallocate: builder.mutation<void, DeallocateRequest>({
        query(body) {
          return {
            url: "/remove-members",
            method: "POST",
            body,
            header: {
              "Content-type": "application/json; charset=UTF-8",
            },
          };
        },
        invalidatesTags: ["Registration"],
      }),
      fetchRegistration: builder.query<Registration, void>({
        query() {
          return {
            url: "/registration",
            method: "GET",
          };
        },
        extraOptions: {
          validation: Registration,
        },
        providesTags: ["Registration"],
      }),
    };
  },
});

export const {
  useSwapMutation,
  useDeallocateMutation,
  useFetchRegistrationQuery,
  useLazyFetchRegistrationQuery,
} = registrationApiSlice;
