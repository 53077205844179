import { Registration, UnsafeRecord } from "@timeedit/registration-shared";
import { createRef, RefObject } from "react";

export function makeTrackListRefs(registration: Registration) {
  return Object.values(registration.courses)
    .flatMap((course) => course?.children ?? [])
    .reduce((acc: UnsafeRecord<string, RefObject<HTMLDivElement>>, cur) => {
      acc[cur] = createRef<HTMLDivElement>();
      return acc;
    }, {});
}
