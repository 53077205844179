import { ColumnType } from "antd/es/table";
import { Divider, Table } from "@timeedit/registration-components";
import {
  NavigateAction,
  ViewProps,
  ViewStatic,
  TitleOptions as RBCTitleOptions,
} from "react-big-calendar";
import {
  ComponentProps,
  ComponentType,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  AllocationControlColumn,
  DateColumn,
  DedicatedColumn,
  ExpandableComponent,
  HiddenColumn,
} from "./ListViewColumns";
import dayjs from "dayjs";
import {
  Registration,
  RegistrationCourse,
  RegistrationTrackList,
  RegistrationTrack,
  Mapping,
  exhaustiveMatchingGuard,
  isDefined,
  UnsafeRecord,
} from "@timeedit/registration-shared";
import { ExpandableConfig } from "antd/es/table/interface";
import { CalendarEvent } from "../../../../utils/events";
import { RegistrationRequiredTranslations } from "../../types";
import {
  AllocationControlContext,
  IdContext,
  RegistrationContext,
  UtilContext,
} from "../../RegistrationContexts";
import { MultiProgress } from "../../../MultiProgress";
import { Button, Tag } from "antd";
import {
  createTrackNumbers,
  getTrackName,
  sortEventsOnTrackNumber,
} from "../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";

type TrackListViewProps = {
  events: CalendarEvent[];
  style?: React.CSSProperties;
};
export function TrackListView(props: TrackListViewProps) {
  const {
    translations,
    mapping,
    dedicatedTracksActions: { presentRelation, presentCategory },
  } = useContext(UtilContext);

  const { registration, mode } = useContext(RegistrationContext);
  const { id } = useContext(IdContext);
  const allocationControlActions = useContext(AllocationControlContext);

  const trackIds = registration.trackLists[id]?.children ?? [];

  const allocationObjectEvents = props.events?.filter((event) =>
    trackIds.includes(event.data.allocationObjectId)
  );

  const sortedEvents = sortEventsOnTrackNumber({
    events: allocationObjectEvents ?? [],
    mapping,
    tracks: registration.tracks,
  });

  const mergedEvents = mergeSameAndLinkedEvents({
    events: sortedEvents,
    registration,
  });

  const trackNumbers = useMemo(
    () =>
      isDefined(allocationObjectEvents)
        ? createTrackNumbers({
            events: allocationObjectEvents,
            mapping,
            tracks: registration.tracks,
          })
        : [],
    [allocationObjectEvents, registration.tracks, mapping]
  );

  const dataSource = createAllocationObjectListViewDataSource({
    events: mergedEvents,
    registration,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  return (
    <div style={props.style}>
      {createTable({
        size: "middle",
        tableLayout: "auto",
        dataSource,
        showHeader: false,
        className: "trackstable",
        columns: createColumns(),
        pagination: false,
        expandable: tableExpandable({
          expandedRowKeys,
          mapping,
          registration,
          translations,
        }),
      })}
    </div>
  );

  function createColumns(): ColumnType<ListDataSource>[] {
    const optionsColumn: ColumnType<ListDataSource> = {
      title: "Option",
      dataIndex: "option",
      key: "option",
      render: (_, { tracks, mergedEvents }) => {
        return (
          <div>
            {tracks.map((track) => {
              const trackName = getTrackName(track, mapping);
              const trackNumberIndex =
                mergedEvents[0].data.trackNumber ??
                trackNumbers.findIndex(
                  (trackNumber) => trackNumber === trackName
                );

              const colorIndex =
                trackNumberIndex === -1 ? "grey" : trackNumberIndex;

              const text =
                tracks.length > 1
                  ? `${mapping.parse(
                      "activityType",
                      track.teObject
                    )} ${trackName}`
                  : trackName;

              return (
                <Tag
                  key={track.id}
                  data-track-id={track.id}
                  className={`tracklabel trackbox trackbox--${
                    tracks.length > 1 ? "multiple" : "single"
                  } rbc-day-slot rbc-event--${colorIndex}`}
                >
                  {text}
                </Tag>
              );
            })}
          </div>
        );
      },
    };
    const dateColumn: ColumnType<ListDataSource> = {
      title: translations.day,
      dataIndex: "day",
      key: "day",
      render: (_, { mergedEvents }) => (
        <DateColumn
          autoWidth={true}
          translations={translations}
          events={mergedEvents}
        />
      ),
    };

    const allocationBarColumn: ColumnType<ListDataSource> = {
      title: translations.allocation,
      dataIndex: "allocation",
      key: "allocation",
      render: (_, { tracks }) => {
        // In the teacher view, we just show the progress value from one of the tracks.
        // If there are a difference between the tracks then we will show it in the issue list that we implement later on.
        return (
          <MultiProgress
            value={tracks[0].seats.raw.taken}
            end={tracks[0].seats.raw.buffer}
            max={tracks[0].seats.raw.total}
            explainValue={translations.allocated}
            explainEnd={translations.BUFFER}
            compact={false}
            formatHelp={() =>
              `(${translations.BUFFER}: ${tracks[0].seats.raw.buffer})`
            }
          />
        );
      },
    };

    const hiddenColumn: ColumnType<ListDataSource> = {
      title: translations.HIDETRACK,
      dataIndex: "hidden",
      key: "hidden",
      render: (_, { tracks }) => {
        const hidden = tracks.some((track) => track.hidden);
        return <HiddenColumn hidden={hidden} label={translations.HIDETRACK} />;
      },
    };

    const dedicatedColumn: ColumnType<ListDataSource> = {
      title: translations.DEDICATETRACK,
      dataIndex: "dedicated",
      key: "dedicated",
      render: (_, { tracks }) => {
        const dedicated = tracks.map((track) => track.dedicated);
        return (
          <DedicatedColumn
            dedicated={dedicated}
            presentCategory={presentCategory}
            presentRelation={presentRelation}
          />
        );
      },
    };

    const controlsColumn: ColumnType<ListDataSource> = {
      title: "",
      dataIndex: "",
      key: "",
      render: (_, { trackList, tracks, mergedEvents }) => {
        const hasConflict = mergedEvents.some(
          (event) => event.data.hasConflict
        );

        return (
          <AllocationControlColumn
            hasConflict={hasConflict}
            trackList={trackList}
            tracks={tracks}
            registration={registration}
            translations={translations}
            allocationControlActions={allocationControlActions}
            mode={mode}
          />
        );
      },
    };

    const toggleRow = (key: string) => {
      if (expandedRowKeys.includes(key)) {
        setExpandedRowKeys(
          expandedRowKeys.filter((k) => key.localeCompare(k.toString()))
        );
        return;
      }
      setExpandedRowKeys([...expandedRowKeys, key]);
    };

    const expandColumn: ColumnType<ListDataSource> = {
      title: "Expand",
      dataIndex: "expand",
      key: "expand",
      render: (_value, record) => {
        const expanded = expandedRowKeys.includes(record.key);
        const css = expanded ? " button-expanded" : "";
        return (
          <Button
            style={{ minWidth: 40 }}
            className={"trackiconbox" + css}
            icon={<InfoCircleOutlined className="trackboxicon" />}
            onClick={(_) => toggleRow(record.key)}
          ></Button>
        );
      },
    };

    switch (mode) {
      case "student": {
        return [optionsColumn, dateColumn, expandColumn, controlsColumn];
      }
      case "teacher": {
        return [
          optionsColumn,
          dateColumn,
          hiddenColumn,
          dedicatedColumn,
          allocationBarColumn,
          expandColumn,
          controlsColumn,
        ];
      }
      default: {
        return exhaustiveMatchingGuard(mode);
      }
    }
  }
}

type MergeEventsProps = {
  events: CalendarEvent[];
  registration: Registration;
};

/**
 * @description Purpose of this function is to group events so they can later be displayed on the same row.
 * @param events - Events to merge
 * @param registration - Registration object
 * @returns Merged events with same allocationObjectId and if they are linked, into the same array.
 */
export function mergeSameAndLinkedEvents({
  events,
  registration,
}: MergeEventsProps) {
  return (
    events.reduce<CalendarEvent[][]>((mergedEvents, currentEvent) => {
      const idIndex = mergedEvents.findIndex((event) => {
        const trackId = event[0].data.allocationObjectId;
        const currentTrackId = currentEvent.data.allocationObjectId;
        return (
          trackId === currentTrackId ||
          registration.tracks[currentTrackId]?.links.includes(trackId)
        );
      });
      if (idIndex !== -1) {
        mergedEvents[idIndex].push(currentEvent);
        mergedEvents[idIndex].sort(
          (a, b) => dayjs(a.start).day() - dayjs(b.start).day()
        );
      } else {
        mergedEvents.push([currentEvent]);
      }

      return mergedEvents;
    }, []) ?? []
  );
}

function AllocationObjectListView(props: ViewProps<CalendarEvent>) {
  return <TrackListView events={props.events ?? []} />;
}

type ListDataSource = {
  course: RegistrationCourse;
  tracks: RegistrationTrack[];
  trackList: RegistrationTrackList;
  mergedEvents: CalendarEvent[];
  key: string;
};

interface CreateTableProps<T extends object>
  extends Omit<ComponentProps<typeof Table<T>>, "dataSource" | "columns"> {
  dataSource: T[];
  columns: ColumnType<T>[];
}
function createTable({
  columns,
  dataSource,
  ...props
}: CreateTableProps<ListDataSource>) {
  return <Table {...props} columns={columns} dataSource={dataSource} />;
}

interface CreateDataSource {
  registration: Registration;
  events: CalendarEvent[][];
}

/**
 * @param registration - Registration object
 * @param events - Events that are groups together (linked or same allocationObjectId)
 * @returns An array of objects that has grouped course and trackList with events and their matching tracks.
 */

export function createAllocationObjectListViewDataSource({
  registration,
  events,
}: CreateDataSource) {
  const indexRecord: UnsafeRecord<string, number> = {};
  return events.reduce((dataSource: ListDataSource[], mergedEvents) => {
    // This is used just to get the trackList
    const trackListId =
      registration.tracks[mergedEvents[0].data.allocationObjectId]?.parentId;

    if (!isDefined(trackListId)) return dataSource;

    const trackList = registration.trackLists[trackListId];

    if (!isDefined(trackList)) return dataSource;

    const course = registration.courses[trackList.parentId];

    if (!isDefined(course)) return dataSource;

    // The tracks on a trackList that are included in mergedEvents
    const tracks = trackList.children.reduce(
      (tracks: RegistrationTrack[], trackId) => {
        const isInMergedEvents = mergedEvents.some(
          (event) => event.data.allocationObjectId === trackId
        );
        const track = registration.tracks[trackId];
        if (!isDefined(track)) return tracks;
        return isInMergedEvents ? [...tracks, track] : tracks;
      },
      []
    );
    const indexRecordKey = tracks.map((track) => track.id).join("-");

    if (!isDefined(indexRecord[indexRecordKey])) {
      indexRecord[indexRecordKey] = 0;
    }
    // key is based on the first element in the merged group
    const key = `${
      mergedEvents[(indexRecord[indexRecordKey] ?? 0) % mergedEvents.length].id
    }`;

    dataSource.push({
      trackList,
      tracks,
      course,
      mergedEvents,
      key,
    });
    indexRecord[indexRecordKey] = (indexRecord[indexRecordKey] ?? 0) + 1;
    return dataSource;
  }, []);
}
type TabelExpandalbeProps = {
  registration: Registration;
  mapping: Mapping;
  translations: RegistrationRequiredTranslations;
  expandedRowKeys: React.Key[];
};
function tableExpandable({
  registration,
  mapping,
  translations,
  expandedRowKeys,
}: TabelExpandalbeProps): ExpandableConfig<ListDataSource> {
  return {
    showExpandColumn: false,
    expandedRowKeys,
    expandedRowRender: ({ mergedEvents }: ListDataSource) => {
      return (
        <>
          {...mergedEvents.map((event, index) => {
            const { data } = event;
            return (
              <div key={`tableExpandable-${data.allocationObjectId}-${index}`}>
                <ExpandableComponent
                  mapping={mapping}
                  translations={translations}
                  event={event}
                  registration={registration}
                />
                {index !== mergedEvents.length - 1 && (
                  <Divider className="resboxdivider" />
                )}
              </div>
            );
          })}
        </>
      );
    },
  };
}

AllocationObjectListView.navigate = (
  date: Date,
  action: NavigateAction,
  props: unknown
): Date => {
  const parameters = { date, action, props };
  return parameters.date;
};

interface TitleOptions extends Partial<RBCTitleOptions> {
  localizer: ViewProps["localizer"];
  length: ViewProps["length"];
}

AllocationObjectListView.title = (
  date: Date,
  { localizer, length }: TitleOptions
): string => {
  const end = localizer.add(date, length, "day");
  return localizer.format({ date, end }, "agendaHeaderFormat");
};

export default AllocationObjectListView as ComponentType<
  ViewProps<CalendarEvent>
> &
  ViewStatic;
