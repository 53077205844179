import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../baseQuery";
import {
  TOrganization,
  TTEUser,
  ITEToken,
  IMapping,
  TTEObject,
  LoadBody,
  TField,
  TType,
} from "@timeedit/registration-shared";
import { z } from "zod";

interface FetchOrganizationParams {
  organizationId: string | null | undefined;
}

export const teApiSlice = createApi({
  reducerPath: "teApi",
  baseQuery,
  tagTypes: ["Organizations", "Users", "Mapping", "Objects", "Types", "Fields"],
  endpoints(builder) {
    return {
      fetchTeOrganization: builder.query<
        TOrganization,
        FetchOrganizationParams
      >({
        query({ organizationId }) {
          return {
            url: `/organizations/${organizationId}`,
            method: "GET",
          };
        },
        extraOptions: {
          validation: TOrganization,
        },
        providesTags: ["Organizations"],
      }),
      fetchTeObjects: builder.query<TTEObject[], LoadBody>({
        query(body: LoadBody) {
          return {
            url: `/load-objects`,
            method: "POST",
            body,
          };
        },
        extraOptions: {
          validation: z.array(TTEObject),
        },
        providesTags: ["Objects"],
      }),
      fetchTeFields: builder.query<TField[], void>({
        query() {
          return {
            url: `/find-fields`,
            method: "POST",
          };
        },
        extraOptions: {
          validation: z.array(TField),
        },
        providesTags: ["Fields"],
      }),
      fetchTeTypes: builder.query<TType[], void>({
        query() {
          return {
            url: `/find-types`,
            method: "POST",
          };
        },
        extraOptions: {
          validation: z.array(TType),
        },
        providesTags: ["Types"],
      }),
      fetchTeUser: builder.query<TTEUser & ITEToken, ITEToken>({
        query(loginData: ITEToken) {
          return {
            url: `/users/${loginData.id}`,
            method: "GET",
          };
        },
        extraOptions: {
          validation: TTEUser.merge(ITEToken),
        },
        transformResponse: (response: TTEUser, _, arg: ITEToken) => {
          return {
            ...response,
            ...arg,
          };
        },
        providesTags: ["Users"],
      }),
      fetchMapping: builder.query<IMapping, void>({
        query() {
          return {
            url: "/mapping",
            method: "GET",
            header: {
              "content-type": "application/json; charset=UTF-8",
            },
          };
        },
        extraOptions: {
          validation: IMapping.refine(
            (schema) => schema.objectTypes.length > 0,
            "Mapping is an empty array. Some mappings are required."
          ),
        },
        providesTags: ["Mapping"],
      }),
      fetchTeServerStatus: builder.query<void, void>({
        query() {
          return {
            url: "/te-server/status",
            method: "GET",
            header: {
              "content-type": "application/json; charset=UTF-8",
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchTeOrganizationQuery,
  useLazyFetchTeOrganizationQuery,
  useFetchTeUserQuery,
  useLazyFetchTeUserQuery,
  useFetchMappingQuery,
  useLazyFetchTeServerStatusQuery,
  useLazyFetchTeObjectsQuery,
  useLazyFetchTeFieldsQuery,
  useLazyFetchTeTypesQuery,
} = teApiSlice;
