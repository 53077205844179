import { OfKind } from "../types";
import { config, parsers } from "./configs";
import { createMappingUtilsForApp } from "./mapping.utils";

export * from "./mapping.utils";
export * from "./mapping.types";
export * from "./configs";

export type MappingShorthand = keyof typeof config;
export type MappingShorthandField = keyof OfKind<"field", typeof config>;

/**
 * Function that creates an instance of the mapping utils for an organization.
 * @returns an instance of the mapping utils for the organization
 */
export const createMappingInstance = createMappingUtilsForApp({
  config,
  parsers,
});

export type Mapping = ReturnType<typeof createMappingInstance>;

export type FetchMappingResult =
  | { status: "loading" }
  | { status: "success"; mapping: Mapping }
  | { status: "error"; error: unknown };
