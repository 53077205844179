import { Button, Text } from "@timeedit/registration-components";
import { RegistrationError } from "@timeedit/registration-shared";
import "./cache.scss";
import {
  useFetchClearCacheMutation,
  useFetchPopulateUserObjectsMutation,
} from "../../redux/api/cache";

export function CachePage() {
  const [triggerPopulateUserObjects, populateUserObjectResult] =
    useFetchPopulateUserObjectsMutation();
  const [triggerClearCache, clearCacheResult] = useFetchClearCacheMutation();

  if (populateUserObjectResult.isError) {
    throw new RegistrationError({
      errors: [populateUserObjectResult.error],
      attemptRecovery: triggerPopulateUserObjects,
    });
  }

  if (clearCacheResult.isError) {
    throw new RegistrationError({
      errors: [clearCacheResult.error],
      attemptRecovery: triggerClearCache,
    });
  }

  return (
    <div className="cache__container">
      <div className="cache__container cache__container__user-objects">
        <Button
          loading={populateUserObjectResult.isLoading}
          onClick={() => triggerPopulateUserObjects()}
        >
          Populate user objects
        </Button>
        {populateUserObjectResult.isSuccess && (
          <Text
            className="populate-cache__container__successText"
            type="success"
          >
            Successfully fetched user objects!
          </Text>
        )}
      </div>
      <div className="cache__container cache__container__clear">
        <Button
          loading={clearCacheResult.isLoading}
          onClick={() => triggerClearCache()}
        >
          Clear cache
        </Button>
        {clearCacheResult.isSuccess && (
          <Text className="cache__container__successText" type="success">
            Successfully cleared cache!
          </Text>
        )}
      </div>
    </div>
  );
}
