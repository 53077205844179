import {
  Registration,
  ScheduleEvent,
  UnsafeRecord,
  isDefined,
} from "@timeedit/registration-shared";
import dayjs from "dayjs";

export interface MinMaxTimestamps {
  minTimestamp: number;
  maxTimestamp: number;
  status?: "initial" | "dynamic";
}

export function findCompleteDateIntervalOfEvents(
  events: UnsafeRecord<string, ScheduleEvent>
) {
  let start = { unix: Infinity, local: "" };
  let end = { unix: -Infinity, local: "" };

  for (const id in events) {
    const event = events[id];

    if (!isDefined(event)) continue;

    if (event.start.unix < start.unix) {
      start = event.start;
    }

    if (event.end.unix > end.unix) {
      end = event.end;
    }
  }
  return { start, end };
}

export function getDateIntervals(
  registration: Registration,
  current: {
    dateInterval: MinMaxTimestamps;
    allowedDateInterval: MinMaxTimestamps;
  }
) {
  if (!Object.keys(registration.events).length) return current;

  const interval = findCompleteDateIntervalOfEvents(registration.events);

  const dateInterval = {
    minTimestamp: interval.start.unix,
    maxTimestamp: interval.end.unix,
    status: "dynamic",
  } as const;

  if (current.dateInterval.status === "initial") {
    return {
      allowedDateInterval: dateInterval,
      dateInterval,
    };
  }

  return {
    allowedDateInterval: current.allowedDateInterval,
    dateInterval,
  };
}

export function createInitialDateIntervals() {
  const allowedInitialState = createInitialAllowedDateIntervals();
  const minTimestamp = allowedInitialState.minTimestamp;
  const maxTimestamp = allowedInitialState.maxTimestamp;
  return { minTimestamp, maxTimestamp, status: allowedInitialState.status };
}

export function createInitialAllowedDateIntervals(): MinMaxTimestamps {
  const minTimestamp = dayjs("2024-01-01").unix();
  const maxTimestamp = dayjs("2024-01-01").add(10, "week").endOf("week").unix();
  return { minTimestamp, maxTimestamp, status: "initial" };
}
