import { z } from "zod";
import { IntegerTransform } from "./transforms";

//-----------------------------------------------------------------------------
// --> AllocationGroupState
//-----------------------------------------------------------------------------

export const allocationGroupStates = [
  "ADJUST",
  "PREFERENCE",
  "SELECT",
  "INCONCLUSIVE",
  "UNKNOWN",
] as const;

export const AllocationGroupState = z.enum(allocationGroupStates);

export type AllocationGroupState = z.infer<typeof AllocationGroupState>;

//-----------------------------------------------------------------------------
// --> AllocationSignal
//-----------------------------------------------------------------------------

export const allocationSignals = ["ISSUE", "OK", "PENDING"] as const;

export const AllocationSignal = z.enum(allocationSignals);

export type AllocationSignal = z.infer<typeof AllocationSignal>;

//-----------------------------------------------------------------------------
// --> AllocationStatus
//-----------------------------------------------------------------------------

export const allocationStatuses = [
  "ALLOCATED_TO_THIS",
  "ALLOCATED_TO_OTHER",
  "NOT_ALLOCATED",
  "MULTIPLE_ALLOCATIONS",
] as const;

export const AllocationStatus = z.enum(allocationStatuses);

export type AllocationStatus = z.infer<typeof AllocationStatus>;

//-----------------------------------------------------------------------------
// --> AllowRegistrationStatus
//-----------------------------------------------------------------------------

export const allowRegistrationStatuses = [
  "DURING_INTERVAL",
  "FORCE_OPEN",
  "FORCE_CLOSE",
] as const;

export const AllowRegistrationStatus = z.enum(allowRegistrationStatuses);

export type AllowRegistrationStatus = z.infer<typeof AllowRegistrationStatus>;

//-----------------------------------------------------------------------------
// --> AllocationObjectState
//-----------------------------------------------------------------------------

export const allocationObjectStates = [
  "NONE",
  "REQUESTED",
  "CONFIRMED",
] as const;

export const AllocationObjectState = z.enum(allocationObjectStates);

export type AllocationObjectState = z.infer<typeof AllocationObjectState>;

//-----------------------------------------------------------------------------
// --> ProblemStatus
//-----------------------------------------------------------------------------

export const problemStatuses = ["OK", "ERROR", "WARNING"] as const;

export const ProblemStatus = z.enum(problemStatuses);

export type ProblemStatus = z.infer<typeof ProblemStatus>;

//-----------------------------------------------------------------------------
// --> ObjectIdsWithType
//-----------------------------------------------------------------------------

export const ObjectIdsWithType = z.object({
  typeId: IntegerTransform,
  objectIds: z.array(IntegerTransform).min(1).optional(),
});

export type ObjectIdsWithType = z.infer<typeof ObjectIdsWithType>;

//-----------------------------------------------------------------------------
// --> Allocation Registration Scopes
//-----------------------------------------------------------------------------

const allocationRegistrationScopes = [
  "TE_REGISTRATION::user",
  "TE_ALLOCATE::user",
  "TE_ALLOCATE::admin",
  "TE_ROOT_USER",
] as const;
export const AllocationRegistrationScope = z.enum(allocationRegistrationScopes);
export type AllocationRegistrationScope = z.infer<
  typeof AllocationRegistrationScope
>;
