import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createInitialAllowedDateIntervals,
  createInitialDateIntervals,
  MinMaxTimestamps,
} from "@timeedit/registration-shared";

interface ScheduleState {
  dateInterval: MinMaxTimestamps;
  allowedDateInterval: MinMaxTimestamps;
}

const initialState: ScheduleState = {
  dateInterval: createInitialDateIntervals(),
  allowedDateInterval: createInitialAllowedDateIntervals(),
};

export const scheduleSlice = createSlice({
  initialState,
  name: "schedule",
  reducers: {
    setDateInterval(
      state,
      action: PayloadAction<Pick<ScheduleState, "dateInterval">>
    ) {
      state.dateInterval = action.payload.dateInterval;
    },
    setAllowedDateInterval(
      state,
      action: PayloadAction<Pick<ScheduleState, "allowedDateInterval">>
    ) {
      state.allowedDateInterval = action.payload.allowedDateInterval;
    },
  },
});

export const { setDateInterval, setAllowedDateInterval } =
  scheduleSlice.actions;
