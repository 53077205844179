import { Badge } from "antd";
import styles from "./signalBadge.module.scss";
import cn from "classnames";
import { AllocationSignal } from "@timeedit/registration-shared";

interface SignalBadgeProps {
  signal: AllocationSignal;
  translations: {
    registered: string;
    notRegistered: string;
  };
  className?: string;
}
export function SignalBadge({
  signal,
  className = "",
  translations,
}: SignalBadgeProps) {
  if (signal === "OK") {
    return (
      <Badge
        status="success"
        className={cn(styles.badge, className)}
        text={`${translations.registered}`}
      />
    );
  }
  return (
    <Badge
      status="error"
      className={cn(styles.badge, className)}
      text={`${translations.notRegistered}`}
    />
  );
}
