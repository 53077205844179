import {
  colorError,
  colorSuccess,
} from "@timeedit/ui-components/lib/src/assets/variables";
import "./multiProgress.scss";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import { convertToString } from "@timeedit/registration-shared";

type MultiProgressProps = {
  value: number;
  end: number;
  max: number;
  compact?: boolean;
  explainValue?: string;
  explainEnd?: string;
  formatHelp: () => string;
};

function limitRange(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

function isUnlimited(value: number) {
  return value === 0;
}

function presentMax(max: number) {
  return max === 0 ? "∞" : max;
}

function calcPercent(taken: number, max: number) {
  if (isUnlimited(max) && taken > 0) return 50;
  return roundUpSmallValues(limitRange((taken / max) * 100, 0, 100));
}

function strokeColor(taken: number, max: number) {
  if (isUnlimited(max)) return colorSuccess;
  const open = max - taken;
  if (open === 0) return colorSuccess;
  if (open > 0) return colorSuccess;
  return colorError;
}

function roundUpSmallValues(value: number) {
  if (value > 0 && value < 1) {
    return 1;
  }
  return value;
}

export function MultiProgress(props: MultiProgressProps) {
  const text = `${props.value} / ${presentMax(props.max)}`;
  const textHelp = props.formatHelp();
  const wrapped =
    textHelp.length < 20
      ? "multiprogress-help multiprogress-text"
      : "multiprogress-help";
  const help = props.compact ? "" : <span className={wrapped}>{textHelp}</span>;
  const percent = calcPercent(props.value, props.max);
  const percentEnd = calcPercent(props.end, props.max);
  const toolTipBuffer = isEmpty(textHelp)
    ? ""
    : ` ${convertToString(props.explainEnd)}: ${textHelp}`;
  const tooltipText = `${convertToString(props.explainValue)}: ${
    props.value
  } \n ${toolTipBuffer}`;
  return (
    <Tooltip overlayStyle={{ whiteSpace: "pre-line" }} title={tooltipText}>
      <div className="multiprogress-base">
        <div className="multiprogress-line">
          <div className="multiprogress-inner">
            <div
              className="multiprogress-bar-right"
              style={{ width: `${percentEnd}%` }}
            />
            <div
              className="multiprogress-bar-left"
              style={{
                width: `${percent}%`,
                backgroundColor: strokeColor(props.value, props.max),
              }}
            />
            <div className="multiprogress-bar-base" />
          </div>
        </div>
        <span className="multiprogress-text">{text}</span>
        {help}
      </div>
    </Tooltip>
  );
}
