import { useRef } from "react";
import {
  NavigateFunction,
  NavigateOptions,
  useNavigate as useNavigateRouterDom,
} from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { useLanguage } from "../lang/translations";
import { isNumber } from "@timeedit/registration-shared";
import { getTo } from "./navigation";

// The actual type is way to complex to pass around,
// with several levels of generics.
interface RTKQueryMutationSubscription {
  abort: () => void;
  reset: () => void;
}

export function useMutationSubscriptionRef() {
  return useRef<RTKQueryMutationSubscription>();
}

export function useNavigate(): NavigateFunction {
  const navigate = useNavigateRouterDom();
  const { currentLanguage } = useLanguage();
  const { loginData } = useAppSelector((state) => state.login);

  return (...args) => {
    if (isNumber(args[0])) {
      navigate(args[0]);
      return;
    }

    const to = getTo({
      to: args[0],
      currentLanguage,
      organizationId: loginData?.organizationId,
    });

    const options = args[1] as NavigateOptions;

    if (to !== undefined) {
      navigate(to, options);
    }
  };
}
