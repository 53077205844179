import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ErrorPage } from "./ErrorPage";
import { Schedule } from "./SchedulePage/Schedule";
import { RegistrationPage } from "./RegistrationPage";
import { AuthRoot } from "./RootPage/AuthRoot";
import { ReportsPage } from "./ReportsPage";
import { AllocationObjectsPage } from "@timeedit/registration-components";
import { CachePage } from "./CachePage";
import { getEndpoints } from "./RouteTypes";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={""} element={<AuthRoot />} errorElement={<ErrorPage />}>
      <Route
        path=":lang?/:orgId?"
        element={<Outlet />}
        errorElement={<ErrorPage />}
      >
        <Route path={getEndpoints().reports.path} element={<ReportsPage />} />
        <Route path={getEndpoints().cache.path} element={<CachePage />} />
        <Route
          path={getEndpoints().registration.path}
          element={<RegistrationPage />}
        >
          <Route path=":id" element={<AllocationObjectsPage />} />
        </Route>
        <Route path={getEndpoints().schedule.path} element={<Schedule />} />
      </Route>
    </Route>
  )
);
