import { isRejectedWithValue, unwrapResult } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { registrationApiSlice } from "./api/registration";
import {
  calculateSignalSummary,
  getDateIntervals,
} from "@timeedit/registration-shared";
import { identifyRTKErrorLocation } from "../utils/errors";
import { setAllowedDateInterval, setDateInterval } from "./slices/schedule";
import { logger } from "@timeedit/registration-components";
import { setSignals } from "./slices/computed";

/**
 * Logs query errors.
 */
export const rtkErrorMiddleware: Middleware =
  (_: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && action?.error) {
      logger.error(
        `[rtk query][${identifyRTKErrorLocation(action)}][rejection]: ${
          "data" in action.error
            ? (action.error.data as { message: string }).message
            : action.error.message
        }`,
        action
      );
    }

    return next(action);
  };

/**
 * Calculates the signals (ISSUE, PENDING, OK), and a summary of those, on each
 * fullfilled catalog fetch request.
 */
export const calculateSignalsMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (
      registrationApiSlice.endpoints.fetchRegistration.matchFulfilled(action)
    ) {
      const registration = unwrapResult(action);
      dispatch(
        setSignals({
          summary: calculateSignalSummary({
            registration,
          }),
        })
      );
    }

    return next(action);
  };

/**
 * Temporarily set initial date interval to include all existing reservations.
 * TODO: This should be replaced with a system where a registration period is
 * set, which controls all reservations that are taken into account for the
 * entire application, so that all computed state is based on the same thing
 * that is visible in the schedule.
 *
 * We keep the old initial interval around as a fallback if there are no
 * reservations or if the existing reservations create a weirdly small
 * interval.
 */
export const setInitialDateIntervalMiddleware: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const state = getState();

    if (
      registrationApiSlice.endpoints.fetchRegistration.matchFulfilled(action)
    ) {
      const registration = unwrapResult(action);
      const currentIntervals = {
        dateInterval: state.schedule.dateInterval,
        allowedDateInterval: state.schedule.allowedDateInterval,
      };

      const { dateInterval, allowedDateInterval } = getDateIntervals(
        registration,
        currentIntervals
      );

      dispatch(
        setAllowedDateInterval({
          allowedDateInterval,
        })
      );

      if (currentIntervals.dateInterval.status === "initial") {
        dispatch(
          setDateInterval({
            dateInterval,
          })
        );
      }
    }

    return next(action);
  };
