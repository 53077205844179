import { isDefined } from "../typechecks";
import { TReservation, TTEFieldValue, TTEObject } from "../zod";
import { toArray } from "./arrays";
import { union, unionBy, uniqBy } from "lodash";

/**
 * Using this, we can create a consistent number id representing a combination
 * of values (like course id and activity type). I am not very familiar with
 * bitwise operators, so I wrote tests to make sure the output is consistent
 * and unique.
 */
export function createIdNumberFromString(...identifiers: (string | number)[]) {
  const str = identifiers.join("");

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash = (hash << 5) + hash + charCode;
    hash = hash >>> 0;
  }

  return hash;
}

export function allRelatedUniqIds(objects?: TTEObject | TTEObject[]): number[] {
  return toArray(objects).reduce<number[]>(
    (result, object) =>
      union(result, object.relations?.flatMap((r) => r.objectIds)),
    []
  );
}

export function allMembersUniqIds(objects: TTEObject | TTEObject[]): number[] {
  return toArray(objects).reduce<number[]>(
    (result, object) => union(result, object.members?.map((r) => r.objectId)),
    []
  );
}

type Id = { id: number };
export function mergeById<T extends Id>(
  a?: (T | undefined)[],
  b?: (T | undefined)[]
): T[] {
  return unionBy(a, b, (o) => o?.id).filter(isDefined);
}

/**
 * @returns fields with the new field values
 */
export const mergeFieldOnId = (
  oldFields: TTEFieldValue[],
  newFields?: TTEFieldValue[]
): TTEFieldValue[] => unionBy(newFields, oldFields, (o) => o.fieldId);

/**
 *
 * @param object TimeEdit object
 * @param newFields the new field values
 * @returns a new object with the new field values
 */
export const mergeFieldsOnObjects = (
  object: TTEObject,
  newFields?: TTEFieldValue[]
): TTEObject => ({
  ...object,
  fields: mergeFieldOnId(object.fields, newFields),
});

export function hasType(object: TTEObject, typeId?: number) {
  if (!isDefined(typeId)) {
    return true;
  }
  if (isAllTypes(typeId)) {
    return true;
  }
  return object.types?.length > 0 && object.types.includes(typeId);
}

export function isAllTypes(typeId?: number) {
  return !isDefined(typeId) || typeId === 0 || typeId === 1;
}

export function uniq(objects: TTEObject[]) {
  return uniqBy(objects, "id");
}

export function ids(objects: TTEObject | TTEObject[]) {
  return toArray(objects).map((o) => o.id);
}

export function firstRelated(objects: TTEObject | TTEObject[], ids: number[]) {
  return toArray(objects).find((object) =>
    object.relations.some((relation) =>
      relation.objectIds.some((id) => ids.includes(id))
    )
  );
}

export function filterObjects(
  objects: TReservation["objects"],
  typeId?: number
) {
  return objects
    ?.filter((o) => isAllTypes(typeId) || o.typeId === typeId)
    .map((t) => t.objectId);
}

export function asSearchObjects(objects: TTEObject[]) {
  return uniq(objects).flatMap((object) =>
    // Have to loop through every type, since it is an array. Usually types has length of 1.
    object.types.map((type) => ({ objectId: object.id, typeId: type }))
  );
}
