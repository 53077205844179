import { SafeParseReturnType, z } from "zod";
import moment from "moment";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getErrorMessage } from "./errors";
import {
  allocateDateTimeFormat,
  allocateDateTimeFormatRegex,
} from "../constants";
import { isDefined } from "../typechecks";
import { isEmptyString } from "./strings";
import { DateInfo, DateTransform } from "../zod";

dayjs.extend(utc);
dayjs.extend(timezone);

export function convertToServerTime(input = 0) {
  return Math.round(input / 1000);
}

export function presentDateMonth(date: dayjs.Dayjs): string {
  return `${dayjs(date).format("D MMM")}`;
}

/**
 * @param date only allows "DD/MM/YYYY HH:mm:ss"
 * @returns date and time
 */
export function presentDateAndTime(date?: string): string {
  if (!isDefined(date) || isEmptyString(date)) return "";
  return formatDateString(splitDateOnly(date)) + " " + splitTimeOnly(date);
}

/**
 * @param date only allows DD/MM/YYYY
 * @returns YYYY-MM-DD
 */
export function formatDateString(date?: string): string {
  if (!isDefined(date) || isEmptyString(date)) return "";
  const [day, month, year] = date.split(/[/-]/);
  return `${year}-${month}-${day}`;
}

/**
 * Split the time part
 * @param dateTime only allows "DD/MM/YYYY HH:mm:ss"
 * @returns
 */
export function splitTimeOnly(dateTime?: string): string {
  if (!isDefined(dateTime) || isEmptyString(dateTime)) return "";
  const time = dateTime.split(" ")[1];
  if (!isDefined(time)) {
    return "";
  }
  return time.substring(0, 5);
}

/**
 * Split the date part
 * @param dateTime only allows "DD/MM/YYYY HH:mm:ss"
 * @returns
 */
export function splitDateOnly(dateTime?: string): string {
  if (!isDefined(dateTime) || isEmptyString(dateTime)) return "";
  return dateTime.split(" ")[0];
}

/**
 * @param unix - unix timestamp
 * @param IANA timezone
 * @param ctx - zod context
 */
export function fromTimestamp(
  unix: number,
  timezone: string,
  ctx?: z.RefinementCtx
) {
  try {
    const localTime = dayjs.unix(unix).tz(timezone);

    return {
      local: localTime.format("YYYY-MM-DD HH:mm:ss"),
      timezone,
      unix,
    };
  } catch (e) {
    if (ctx) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: getErrorMessage(e),
      });
    } else {
      throw e;
    }
    return {
      local: "",
      unix: 0,
      timezone: "",
    };
  }
}

/**
 * @param date - string with format DD/MM/YYYY or DD-MM-YYYY like they seem to
 * be in the date fields on teObjects. Time can be added after like this:
 * DD/MM/YYYY HH:mm:ss.
 * @param timezone - IANA timezone
 * @param ctx - zod context
 * @see https://day.js.orgs/docs/en/parse/string
 */
export function fromTeDateString(
  input: string,
  timezone: string,
  ctx?: z.RefinementCtx
) {
  try {
    const [date, time] = input.split(" ");
    const [day, month, year] = date.split(/[/-]/);
    const dayjsDate = `${month}/${day}/${year}${time ? ` ${time}` : ""}`;
    const localTime = dayjs(dayjsDate).tz(timezone, true);

    return {
      local: localTime.format("YYYY-MM-DD HH:mm:ss"),
      timezone,
      unix: localTime.unix(),
    };
  } catch (e) {
    if (ctx) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: getErrorMessage(e),
      });
    } else {
      throw e;
    }
    return {
      local: "",
      timezone: "",
      unix: 0,
    };
  }
}

/* Determines whether registration period (or any other period in same date format) is currently under way
(= current date is between start and end date).
If there is no start date, it is assumed that "startDate" is "beginning of time".
If there is no end date, it is assumed that "endDate" is "end of time".
*/
export const registrationPeriodRunning = (
  startDate: string | null | undefined,
  endDate: string | null | undefined
): boolean => {
  if (!startDate && !endDate) return false;

  function checkDate(dateInput: string | null | undefined) {
    const timeFormatWithoutHour = "DD/MM/YYYY";
    const timeFormatWithoutHourRegex = new RegExp(
      "[0-3]\\d\\/[0-1]\\d\\/2\\d\\d\\d"
    );
    if (
      dateInput &&
      !(
        allocateDateTimeFormatRegex.test(dateInput) ||
        timeFormatWithoutHourRegex.test(dateInput)
      )
    ) {
      throw new Error(
        `Invalid date format: ${dateInput}. Must preferably be in format ${allocateDateTimeFormat} or (less preferably) in legacy data as ${timeFormatWithoutHour}.`
      );
    }
  }

  checkDate(startDate);
  checkDate(endDate);

  const startDateMoment = startDate
    ? moment(startDate, allocateDateTimeFormat)
    : moment("1073-04-22"); // Arbitrary date in the past
  const endDateMoment = endDate
    ? moment(endDate, allocateDateTimeFormat)
    : moment("4960-04-22"); // Arbitrary date in the future

  const now = moment(Date.now());

  const afterStartDate = startDateMoment.isSameOrBefore(now);
  const beforeEndDate = endDateMoment.isSameOrAfter(now);

  return afterStartDate && beforeEndDate;
};

const timeMap = new Map<string, Map<number, DateInfo>>();

export function parseDate(
  date: unknown,
  timezone: string
): SafeParseReturnType<unknown, DateInfo> {
  const map = findTimeMap(timezone);
  const found = map.get(date);
  if (isDefined(found)) {
    return { data: found, success: true };
  }
  const result = DateTransform(timezone).safeParse(date);
  if (result.success) {
    map.set(date, result.data);
  }
  return result;
}

function findTimeMap(timezone: string) {
  const map = timeMap.get(timezone);
  if (isDefined(map)) {
    return map;
  }
  const newMap = new Map();
  timeMap.set(timezone, newMap);
  return newMap;
}
