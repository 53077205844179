import { z } from "zod";

// Why both a 'util' file and a 'helpers' file? 'helpers' has helper functions
// that need testing, and 'util' has utility types, validators. And Jest has
// some trouble with Zod and says ZUnsafeRecord is not a function.

/**
 * Creates a validator for records that makes values possibly undefined.
 * For use with records that have arbitrary strings as keys, which makes them
 * neither exhaustive nor type safe.
 */
export function ZUnsafeRecord<
  RecordKey extends z.ZodString | z.ZodNumber | z.ZodSymbol,
  RecordValue extends z.ZodTypeAny,
>(key: RecordKey, value: RecordValue) {
  return z.record(key, value.or(z.undefined()));
}
