import { z } from "zod";
import {
  isArray,
  isDefined,
  isNumber,
  isRecord,
  isString,
} from "../typechecks";
import { fromTeDateString, fromTimestamp } from "../functions/dates";
import { allocateDateTimeFormatRegex } from "../constants";
import { CurrentPeriod, DateRange, ViewLink, ViewLinkParsed } from "./types";
import { integerArrayParser } from "../mapping/parsers";
import { safeFromJSONObject } from "../functions";

export const NumberTransform = z
  .unknown()
  .transform((val) => Number(val))
  .pipe(z.number());

export const IntegerTransform = z
  .unknown()
  .transform((val) => (isFinite(Number(val)) ? Math.round(Number(val)) : 0))
  .pipe(z.number());

export const NonNegativeIntegerTransform = z
  .unknown()
  .transform((val) => {
    const num = Number(val);
    if (isFinite(num) && num >= 0) {
      return Math.round(num);
    }
    return 0;
  })
  .pipe(z.number());

export const ObjectIdTransform = z
  .unknown()
  .transform((it) => {
    if (it === undefined || it === null) {
      return "";
    }

    if (isRecord(it)) {
      return it.toString();
    }

    if (isNumber(it)) {
      return it.toString();
    }

    if (isString(it)) {
      return it;
    }

    return "";
  })
  .pipe(z.string());

export const StringTransform = z
  .unknown()
  .transform((it) => {
    if (typeof it === "object") return JSON.stringify(it);
    return isDefined(it) ? `${it}` : "";
  })
  .pipe(z.string());

export const BooleanTransform = z
  .unknown()
  .transform((it) => {
    return Boolean(it);
  })
  .pipe(z.boolean());

export const BooleanFieldTransform = z
  .unknown()
  .transform((it) => {
    if (isArray(it)) {
      return Boolean(it[0]);
    }
    return Boolean(it);
  })
  .pipe(z.boolean());

export const NumberFieldTransform = z
  .unknown()
  .transform((it) => {
    if (isArray(it)) {
      // If field type (kind) is Integer and has 0 as value,
      // it will convert to an empty array on the rest api call.
      // We set it back here.
      if (it.length === 0) return 0;

      return Number(it[0]);
    }
    return Number(it);
  })
  .pipe(z.number());

export const StringFieldTransform = z
  .unknown()
  .transform((it) => {
    if (isArray(it)) {
      return it.join(", ");
    }
    return it ?? "";
  })
  .pipe(z.string());

export const ExtractIdTransform = z
  .union([z.object({ id: z.string() }), z.string()])
  .transform((it) => {
    if (isRecord(it)) {
      return it.id;
    }
    return it;
  })
  .pipe(z.string());

export const DateInfo = z.object({
  local: z.string(),
  timezone: z.string().optional(),
  unix: z.number().int().nonnegative(),
});

export type DateInfo = z.infer<typeof DateInfo>;

export const FilterValueTransform = z
  .unknown()
  .transform((it) => {
    if (it === undefined) return [];
    if (isArray(it)) {
      return it.map((val) => StringTransform.parse(val));
    }
    return [StringTransform.parse(it)];
  })
  .pipe(z.array(z.string()));

export const DateTransform = (timezone: string) =>
  z
    .string()
    .superRefine((it, ctx) => {
      if (!allocateDateTimeFormatRegex.test(it)) {
        ctx.addIssue({
          message: `Wrong date format: ${it}`,
          code: z.ZodIssueCode.invalid_date,
        });
        return false;
      }
      return true;
    })
    .transform((it, ctx) => {
      return fromTeDateString(it, timezone, ctx);
    })
    .pipe(DateInfo)
    .or(
      z
        .number()
        .int()
        .nonnegative()
        .transform((it, ctx) => {
          return fromTimestamp(it, timezone, ctx);
        })
        .pipe(DateInfo)
    )
    .or(DateInfo);

export function parseDateRange(current: CurrentPeriod): DateRange | undefined {
  if (!isDefined(current)) {
    return undefined;
  }
  if (!isDefined(current.range)) {
    return undefined;
  }
  if (!isDefined(current.range.start)) {
    return undefined;
  }
  if (!isDefined(current.range.end)) {
    return undefined;
  }
  return {
    startDate: current.range.start,
    endDate: current.range.end,
  };
}

export function parseViewLink(raw: ViewLink | undefined): ViewLinkParsed {
  const current: CurrentPeriod = raw?.currentPeriod
    ? safeFromJSONObject(raw.currentPeriod)
    : {};
  const currentFieldIds: number[] = isDefined(current.fields)
    ? integerArrayParser(Object.keys(current.fields))
    : [];
  const viewLink: ViewLinkParsed = {
    ...raw,
    current,
    dateRange: parseDateRange(current),
    currentFieldIds,
  };
  return viewLink;
}
