import { configureStore } from "@reduxjs/toolkit";
import { loginDataReducer } from "./slices/user";
import { computedSlice } from "../redux/slices/computed";
import {
  calculateSignalsMiddleware,
  rtkErrorMiddleware,
  setInitialDateIntervalMiddleware,
} from "./middleware";
import { teApiSlice } from "./api/te";
import { registrationApiSlice } from "./api/registration";
import { scheduleSlice } from "./slices/schedule";
import { languageSlice } from "./slices/lang";
import { reportsApiSlice } from "./api/reports";
import { cacheApiSlice } from "./api/cache";

export const store = configureStore({
  reducer: {
    computed: computedSlice.reducer,
    login: loginDataReducer,
    schedule: scheduleSlice.reducer,
    language: languageSlice.reducer,
    [teApiSlice.reducerPath]: teApiSlice.reducer,
    [registrationApiSlice.reducerPath]: registrationApiSlice.reducer,
    [reportsApiSlice.reducerPath]: reportsApiSlice.reducer,
    [cacheApiSlice.reducerPath]: cacheApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(teApiSlice.middleware)
      .concat(registrationApiSlice.middleware)
      .concat(reportsApiSlice.middleware)
      .concat(cacheApiSlice.middleware)
      .concat(rtkErrorMiddleware)
      .concat(calculateSignalsMiddleware)
      .concat(setInitialDateIntervalMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
