import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Registration, SignalsPayload } from "@timeedit/registration-shared";

type RegistrationState =
  | {
      isLoading: true;
      isFetching: false;
      isSuccess: false;
      isError: false;
      error: undefined;
      data: undefined;
    }
  | {
      isLoading: false;
      isFetching: true;
      isSuccess: false;
      isError: false;
      error: undefined;
      data: Registration;
    }
  | {
      isLoading: false;
      isFetching: false;
      isSuccess: false;
      isError: true;
      error: FetchBaseQueryError | SerializedError;
      data: undefined;
    }
  | {
      isLoading: false;
      isFetching: false;
      isSuccess: true;
      isError: false;
      error: undefined;
      data: Registration;
    };

interface ComputedState {
  signal: SignalsPayload;
  registration: RegistrationState;
}

const initialState: ComputedState = {
  signal: {
    summary: {
      OK: 0,
      ISSUE: 0,
      PENDING: 0,
    },
  },
  registration: {
    isLoading: true,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: undefined,
    data: undefined,
  },
};

export const computedSlice = createSlice({
  initialState,
  name: "computed",
  reducers: {
    setSignals(state, action: PayloadAction<SignalsPayload>) {
      state.signal = action.payload;
    },
    setRegistrationState(state, action: PayloadAction<RegistrationState>) {
      state.registration = action.payload;
    },
  },
});

export const { setSignals } = computedSlice.actions;
