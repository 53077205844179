import { Tabs, TabList, Tab } from "@reach/tabs";
import styles from "./navbar.module.scss";
import { ReactNode, HtmlHTMLAttributes } from "react";

interface NavbarProps {
  onChange: (index: number) => void;
  index: number;
  Endpoints: Endpoint[];
  navigateTranslation: string;
  tabsProps?: Omit<HtmlHTMLAttributes<HTMLDivElement>, "onChange">;
}

interface Endpoint {
  path: string;
  name: string;
  icon?: ReactNode;
}

export function Navbar({
  index,
  onChange,
  Endpoints,
  navigateTranslation,
  tabsProps,
}: NavbarProps) {
  return (
    <Tabs index={index} onChange={onChange} {...tabsProps}>
      <TabList className={styles.navbar}>
        {Endpoints.map(({ name, path, icon }) => {
          return (
            <Tab aria-label={`${navigateTranslation} ${name}`} key={path}>
              {icon ?? <p>{name}</p>}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
}
