import { translations } from "@timeedit/registration-shared";

export function getEndpoints() {
  return {
    root: {
      path: "",
      name: translations.registration,
    },
    reports: {
      path: "reports",
      name: "reports",
    },
    cache: {
      path: "cache",
      name: "cache",
    },
    registration: {
      path: "registration",
      name: translations.registration,
    },
    allocationObjects: {
      path: "registration/:id",
      name: translations.allocationObjects,
    },
    schedule: {
      path: "schedule",
      name: translations.schedule,
    },
  } as const;
}

type Endpoints = ReturnType<typeof getEndpoints>;
type EndPoint = Endpoints[keyof Endpoints];
export type EndPointPath = EndPoint["path"];
