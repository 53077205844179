import { z } from "zod";
import { ITEToken } from "./types";

//-----------------------------------------------------------------------------
// --> AuthParams from Authorized Express Request
//-----------------------------------------------------------------------------

export const AuthParams = z.object({
  serverUrl: z.string().optional(),
  token: z.string(),
  user: ITEToken,
  organizationId: z.string(),
  listOptions: z
    .object({
      limit: z.number(),
      page: z.string(),
      sortBy: z.string().optional(),
    })
    .optional(),
});

export type AuthParams = z.infer<typeof AuthParams>;
