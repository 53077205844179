import { isDefined, isNumber } from "@timeedit/registration-shared";
import { CSSProperties, MouseEvent } from "react";
import { ConflictNotification } from "../../../Conflict";
import { SuccessIcon } from "./SuccessIcon";
import "./weekEvent.scss";
import { CalendarEvent } from "../../../../utils/events";
interface WeekEventProps {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  event: CalendarEvent;
  className: string;
}

export function WeekEvent({
  onClick,
  event,
  style,
  className,
}: WeekEventProps) {
  const weekStyle = style ?? {};
  const leftPercentage = extractPercentageFromCalc(String(weekStyle.left));
  // The style we get from react big calendar is incomplete
  const widthPercentage = extractPercentageFromCalc(String(weekStyle.width));
  return (
    <button
      className={className}
      style={{
        ...weekStyle,
        left: `${leftPercentage}%`,
        width: calculateWidth(),
        cursor: event.data.trackNumber === -1 ? "unset" : "pointer",
      }}
      onClick={onClick}
      disabled={event.data.trackNumber === -1}
    >
      <div className={"rbc-event__content"} style={{ background: "inherit" }}>
        <div>{event.title}</div>
        <div>{event.body}</div>
        <div className="week-event__footer">
          <div className="week-event__footer--withText">
            {event.footer?.wantToShowSuccess && <SuccessIcon useText={true} />}
          </div>
          <div className="week-event__footer--withText">
            {event.footer?.wantToShowConflict && (
              <ConflictNotification useText={false} />
            )}
          </div>
          <div className="week-event__footer--withText">
            {!event.footer?.wantToShowSuccess &&
              isDefined(event.footer?.text) &&
              event.footer.text}
          </div>
        </div>
      </div>
    </button>
  );

  // Standard RBC width have bigger margin between events and overlaps more on
  // other days on small screens
  function calculateWidth() {
    return `calc(${widthPercentage}% - 1px)`;
  }
}

/**
 * @returns The first percentage value
 */
export function extractPercentageFromCalc(input: string): number {
  const regex = /calc\((\s*\d+\.?\d+)%/;
  const match = input.match(regex);

  if (match && match.length > 1) {
    const percentageValue = Number(match[1].trim());
    if (isNumber(percentageValue)) {
      return percentageValue;
    }
  }

  return 0;
}
