import {
  RegistrationTrack,
  Mapping,
  exhaustiveMatchingGuard,
} from "@timeedit/registration-shared";
import { HTMLProps } from "react";
import { RegistrationRequiredTranslations } from "../../types";

interface Props extends HTMLProps<HTMLSpanElement> {
  track?: RegistrationTrack;
  mapping: Mapping;
  translations: RegistrationRequiredTranslations;
}

export function ClosedReason({
  track,
  mapping,
  translations,
  ...spanProps
}: Props) {
  const allowRegistration = mapping.parse("allowRegistration", track?.teObject);

  if (track?.open) return null;

  switch (allowRegistration) {
    case "FORCE_OPEN": {
      return null;
    }
    case "FORCE_CLOSE": {
      return (
        <span {...spanProps}>
          {translations.closed}. {translations.forcedClose}
        </span>
      );
    }
    case "DURING_INTERVAL": {
      return (
        <span {...spanProps}>
          {translations.closed}. {translations.registrationIs}{" "}
          {mapping.string("startDate", track?.teObject)} -{" "}
          {mapping.string("endDate", track?.teObject)}
        </span>
      );
    }
    default: {
      return exhaustiveMatchingGuard(allowRegistration);
    }
  }
}
