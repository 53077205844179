import { Spin as AntdSpin, SpinProps as AntdSpinProps } from "antd";
import cn from "classnames";
import styles from "./spin.module.scss";

// eslint-disable-next-line import/no-unused-modules
export interface SpinProps extends AntdSpinProps {
  contained?: boolean;
  containerClassName?: string;
}

export function Spin({
  contained = false,
  containerClassName = "",
  ...props
}: SpinProps) {
  if (contained) {
    return (
      <div className={cn(styles.container, containerClassName)}>
        <AntdSpin {...props}>{props.children}</AntdSpin>
      </div>
    );
  }
  return <AntdSpin {...props}>{props.children}</AntdSpin>;
}
