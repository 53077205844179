import { translations } from "@timeedit/registration-shared";
import "./conflict.scss";
import Icon from "@ant-design/icons";
import { Typography } from "antd";

type ConflictNotificationProps = {
  useText?: boolean;
  className?: string;
  text?: string;
  ids?: number[];
};

// Imported from figma
const ConflictSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3334 12.7917C13.3334 13.0569 13.4388 13.3112 13.6263 13.4988C13.8138 13.6863 14.0682 13.7917 14.3334 13.7917C14.5986 13.7917 14.853 13.6863 15.0405 13.4988C15.2281 13.3112 15.3334 13.0569 15.3334 12.7917C15.3334 12.5265 15.2281 12.2721 15.0405 12.0846C14.853 11.897 14.5986 11.7917 14.3334 11.7917C14.0682 11.7917 13.8138 11.897 13.6263 12.0846C13.4388 12.2721 13.3334 12.5265 13.3334 12.7917ZM13.8334 10.1667H14.8334C14.9022 10.1667 14.9584 10.1104 14.9584 10.0417V2.79167C14.9584 2.72292 14.9022 2.66667 14.8334 2.66667H13.8334C13.7647 2.66667 13.7084 2.72292 13.7084 2.79167V10.0417C13.7084 10.1104 13.7647 10.1667 13.8334 10.1667Z"
      fill="#CF2C1D"
    />
    <path
      d="M11.3334 3.125H8.70841V2.125C8.70841 2.05625 8.65216 2 8.58341 2H7.70841C7.63966 2 7.58341 2.05625 7.58341 2.125V3.125H4.91675V2.125C4.91675 2.05625 4.8605 2 4.79175 2H3.91675C3.848 2 3.79175 2.05625 3.79175 2.125V3.125H1.16675C0.890186 3.125 0.666748 3.34844 0.666748 3.625V12.6667C0.666748 12.9432 0.890186 13.1667 1.16675 13.1667H11.3334C11.61 13.1667 11.8334 12.9432 11.8334 12.6667V3.625C11.8334 3.34844 11.61 3.125 11.3334 3.125ZM10.7084 12.0417H1.79175V7.4375H10.7084V12.0417ZM1.79175 6.375V4.25H3.79175V5C3.79175 5.06875 3.848 5.125 3.91675 5.125H4.79175C4.8605 5.125 4.91675 5.06875 4.91675 5V4.25H7.58341V5C7.58341 5.06875 7.63966 5.125 7.70841 5.125H8.58341C8.65216 5.125 8.70841 5.06875 8.70841 5V4.25H10.7084V6.375H1.79175Z"
      fill="#CF2C1D"
    />
  </svg>
);

const ConflictIcon = () => (
  <Icon component={ConflictSvg} className="conflict-notification__svg" />
);

export function ConflictNotification({
  useText,
  text,
  ids,
}: ConflictNotificationProps) {
  return (
    <div data-reservation-ids={ids}>
      <ConflictIcon />
      {useText && (
        <Typography.Text className="conflict-notification__text">
          {translations.conflict} {text}
        </Typography.Text>
      )}
    </div>
  );
}
