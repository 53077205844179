import { getErrorMessage } from "../functions";
import { isDefined, isRecord } from "./primitives";

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage<T>(
  error: unknown
): error is T & { message: string } {
  return (
    isRecord(error) &&
    Object.prototype.hasOwnProperty.call(error, "message") &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'logId' property
 *
 */
export function isErrorWithLogId<T>(
  error: unknown
): error is T & { logId: string | string[] } {
  return isRecord(error) && "logId" in error;
}

/**
 * Type predicate to narrow an unknown error to error with a stack trace
 *
 */
export function isErrorWithStack<T>(
  error: unknown
): error is T & { stack: string } {
  return isRecord(error) && "stack" in error;
}

export interface TeServerError {
  status: number;
  message: string;
  localizedMessage?: string;
  errorCode?: number;
}

/**
 * Type predicate to narrow an unknown to an object of type TeServerError
 */
export function isTeServerError(error: unknown): error is TeServerError {
  return (
    isRecord(error) &&
    typeof error?.status === "number" &&
    typeof error?.message === "string"
  );
}
type RegistrationErrorConstructor = {
  errors: unknown[];
  attemptRecovery?: () => void;
};
export class RegistrationError extends Error {
  public recover: () => void = () => location.reload();
  constructor(con: RegistrationErrorConstructor) {
    const errorMessage = con.errors.reduce((msg: string, error) => {
      return `${msg}\n${getErrorMessage(error)}`;
    }, "");
    super(errorMessage);
    Object.setPrototypeOf(this, RegistrationError.prototype);

    // Set properties directly on the instance object
    con.errors.forEach((error) => {
      if (typeof error === "object" && error !== null) {
        Object.keys(error).forEach((key) => {
          // Skip the 'message'
          if (key === "message") {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!Array.isArray((this as any)[key])) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this as any)[key] = [];
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any)[key].push((error as any)[key]);
        });
      }
    });

    if (isDefined(con.attemptRecovery)) {
      this.recover = con.attemptRecovery;
    }
  }
}
