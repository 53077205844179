import { translations } from "@timeedit/registration-shared";
import "./successIcon.scss";
import { Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";

type SuccessIconProps = {
  useText?: boolean;
  className?: string;
};

export function SuccessIcon({
  useText,
  className = "event-success-icon",
}: SuccessIconProps) {
  return (
    <span className={className}>
      <CheckOutlined />
      {useText && (
        <Typography.Text className="event-success-icon__text">
          {translations.registered}
        </Typography.Text>
      )}
    </span>
  );
}
