import { ListView, Text } from "@timeedit/registration-components";
import {
  Registration,
  Mapping,
  isDefined,
} from "@timeedit/registration-shared";
import { TrackListView } from "../CalendarListView/AllocationObjectListView";
import { CalendarEvent } from "../../../../utils/events";
import { RegistrationRequiredTranslations } from "../../types";

type EventWithRelated = {
  event: CalendarEvent;
  relatedEvents: CalendarEvent[];
};
export interface EventModalContentProps {
  eventsWithRelated: Partial<EventWithRelated>[];
  title: JSX.Element;
}

export function AllocationObjectModalContent({
  eventsWithRelated,
  title,
}: EventModalContentProps) {
  if (eventsWithRelated.length === 0) {
    return <Text>No events to display!</Text>;
  }

  const trackListEvents = createTrackListEvents({ eventsWithRelated });

  return (
    <div>
      {title}
      <TrackListView events={trackListEvents} />
    </div>
  );
}

type ScheduleModalContentProps = {
  translations: RegistrationRequiredTranslations;
  mapping: Mapping;
  registration: Registration;
};
export function createScheduleModalContent({
  mapping,
  registration,
  translations,
}: ScheduleModalContentProps) {
  const ScheduleModalContent = ({
    eventsWithRelated,
  }: EventModalContentProps) => {
    if (eventsWithRelated.length === 0) {
      return <Text>404</Text>;
    }

    const trackListEvents = createTrackListEvents({ eventsWithRelated });

    return (
      <ListView
        events={trackListEvents}
        mapping={mapping}
        registration={registration}
        translations={translations}
      />
    );
  };
  return ScheduleModalContent;
}

type CreateTrackListEventsProps = {
  eventsWithRelated: EventModalContentProps["eventsWithRelated"];
};
function createTrackListEvents({
  eventsWithRelated,
}: CreateTrackListEventsProps) {
  return eventsWithRelated.reduce<CalendarEvent[]>((addedEvents, curr) => {
    const currentEvent = curr.event;
    if (!isDefined(currentEvent)) return addedEvents;
    const eventsToAdd = [currentEvent, ...(curr.relatedEvents ?? [])].filter(
      (event) => !addedEvents.some((addedEvent) => addedEvent.id === event.id)
    );

    return [...addedEvents, ...eventsToAdd];
  }, []);
}
