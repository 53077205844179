import { Params } from "react-router-dom";
import { EndPointPath, getEndpoints } from "./RouteTypes";

export function isValidBasePath(
  path: string,
  endpoints: ReturnType<typeof getEndpoints>
): path is EndPointPath {
  const endpointPaths = Object.values(endpoints).map(
    (endpoint) => endpoint.path
  );

  return endpointPaths.includes(path as EndPointPath);
}

interface PathParameters {
  path: string;
  params: Readonly<Params<string>>;
}

export function removePathParameters({ params, path }: PathParameters): string {
  const segments = path.split("/");
  const paramValues = Object.values(params);
  const newSegments = segments.filter(
    (segment) => !paramValues.includes(segment)
  );

  return newSegments.join("/");
}

export function removeFirstLastBackslash(s: string) {
  return s.replace(/^\/|\/$/g, "");
}
