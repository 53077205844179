import {
  isNullish,
  isErrorWithMessage,
  hasData,
  isString,
  isRecord,
  isNumber,
} from "../typechecks";
import { safeToJSON } from "./json";

export function getStatusCode(error: unknown): number {
  if (isNullish(error)) {
    return 500;
  }

  if (isNumber(error)) {
    return error;
  }

  for (const key of ["statusCode", "status"]) {
    if (isRecord(error) && key in error) {
      const value = error[key];
      if (isNumber(value)) {
        return getStatusCode(value);
      }
      if (isString(value)) {
        const numberValue = parseInt(value, 10);
        return isNaN(numberValue) ? 500 : numberValue;
      }
    }
  }

  if (hasData(error)) {
    return getStatusCode(error.data);
  }
  if (isRecord(error) && "error" in error) {
    return getStatusCode(error.error);
  }

  return 500;
}

/**
 * Function to extract a meaningful message from errors of different types.
 */
export function getErrorMessage(error: unknown): string {
  if (isNullish(error)) {
    return "";
  }

  if (isString(error)) {
    return error;
  }

  if (isErrorWithMessage(error)) {
    return getErrorMessage(error.message);
  }

  if (hasData(error)) {
    return getErrorMessage(error.data);
  }
  if (isRecord(error) && "error" in error) {
    return getErrorMessage(error.error);
  }

  return safeToJSON(error);
}
