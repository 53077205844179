import { ToolbarProps as RBCToolbarProps, Views } from "react-big-calendar";
import dayjs from "dayjs";
import { Segmented } from "antd";
import { CalendarOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  MinMaxTimestamps,
  presentDateMonth,
} from "@timeedit/registration-shared";
import { getGraphicalView } from "../RegistrationCalendar";
import { Dispatch, SetStateAction } from "react";
import { SelectOptions } from "../../utils";
import { RegistrationRequiredTranslations } from "../../types";
import { CalendarEvent } from "../../../../utils/events";
import { Text } from "@timeedit/registration-components";

export enum DisplayState {
  Graphical = "Graphical",
  Text = "Text",
}

type CalendarToolbarProps = RBCToolbarProps<CalendarEvent> & {
  setDisplayState: Dispatch<SetStateAction<DisplayState>>;
  displayState: DisplayState;
  setViewOption: Dispatch<SetStateAction<SelectOptions>>;
  viewOption: SelectOptions;
  setDateInterval: Dispatch<SetStateAction<MinMaxTimestamps>>;
  dateInterval: MinMaxTimestamps;
  allowedDateInterval: MinMaxTimestamps;
  translations: RegistrationRequiredTranslations;
};
export function CalendarToolbar(props: CalendarToolbarProps) {
  const {
    onView,
    setDisplayState,
    displayState,
    viewOption,
    allowedDateInterval,
    translations,
  } = props;

  const allowedStartDate = dayjs.unix(allowedDateInterval.minTimestamp);
  const allowedEndDate = dayjs.unix(allowedDateInterval.maxTimestamp);

  return (
    <div className="rbc-toolbar">
      <Segmented
        onChange={(value) => {
          const newDisplayState = value as DisplayState;
          switch (newDisplayState) {
            case DisplayState.Graphical:
              onView(getGraphicalView(viewOption));
              break;

            case DisplayState.Text:
              onView(Views.AGENDA);
              break;
          }
          setDisplayState(newDisplayState);
        }}
        defaultValue={displayState}
        options={[
          {
            label: translations.textview,
            value: DisplayState.Text,
            icon: <UnorderedListOutlined alt="Text List" />,
          },
          {
            label: translations.graphicalview,
            value: DisplayState.Graphical,
            icon: <CalendarOutlined alt="Graphical" />,
          },
        ]}
      />
      {viewOption === "All weeks" && (
        <Text>
          {presentDateMonth(allowedStartDate)} -{" "}
          {presentDateMonth(allowedEndDate)} (
          {presentWeeks(
            allowedStartDate,
            allowedEndDate,
            translations.weekAbbreviation
          )}
          )
        </Text>
      )}
      {/*
      {viewOption === "Week interval" && (
        <RangePicker
          picker="week"
          disabledDate={disabledDate()}
          format={(date) =>
            rangePickerWeekFormat({
              date,
              weekAbbreviationTranslation: translations.weekAbbreviation,
            })
          }
          onChange={rangePickerOnChange()}
          defaultValue={[
            dayjs.unix(dateInterval.minTimestamp),
            dayjs.unix(dateInterval.maxTimestamp),
          ]}
          allowClear={false}
        />
      )}
       
      <span className="rbc-btn-group">
        <Select
          defaultValue={viewOption}
          options={createSelectOptions()}
          onChange={(value) => {
            setViewOption(value);
            onView(
              displayState === DisplayState.Graphical
                ? getGraphicalView(value)
                : Views.AGENDA
            );
          }}
        />
      </span>
       */}
    </div>
  );

  function presentWeeks(
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    weeks: string
  ): string {
    return `${weeks} ${dayjs(start).week()} - ${dayjs(end).week()}`;
  }
}
