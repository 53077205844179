import { Button } from "@timeedit/registration-components";
import {
  useFetchCourseAllocationReportMutation,
  useFetchAnonymousStudentRegistrationReportMutation,
  useFetchStudentRegistrationReportMutation,
} from "../../redux/api/reports";
import { RegistrationError } from "@timeedit/registration-shared";
import "./reports.scss";

export function ReportsPage() {
  const [triggerFetchStudentReg, studentReg] =
    useFetchStudentRegistrationReportMutation();
  const [triggerFetchAnonymousStudentReg, studentRegAnon] =
    useFetchAnonymousStudentRegistrationReportMutation();
  const [triggerFetchCourseAlloc, courseAlloc] =
    useFetchCourseAllocationReportMutation();

  if (courseAlloc.isError) {
    throw new RegistrationError({
      errors: [courseAlloc.error],
      attemptRecovery: triggerFetchCourseAlloc,
    });
  }

  if (studentRegAnon.isError) {
    throw new RegistrationError({
      errors: [studentRegAnon.error],
      attemptRecovery: triggerFetchAnonymousStudentReg,
    });
  }

  if (studentReg.isError) {
    throw new RegistrationError({
      errors: [studentReg.error],
      attemptRecovery: triggerFetchAnonymousStudentReg,
    });
  }

  return (
    <div className="reports__container">
      <Button
        loading={courseAlloc.isLoading}
        onClick={() => triggerFetchCourseAlloc()}
      >
        Download course allocations report
      </Button>
      <Button
        loading={studentReg.isLoading}
        onClick={() => triggerFetchStudentReg()}
      >
        Download student registrations report
      </Button>
      <Button
        loading={studentRegAnon.isLoading}
        onClick={() => triggerFetchAnonymousStudentReg()}
      >
        Download anonymous student registrations report
      </Button>
    </div>
  );
}
