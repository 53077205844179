import {
  EAppId,
  EAppProperties,
  EApplicationObjectTypeGroup,
  EObjectTypeMappingStatuses,
} from "@timeedit/types/lib/enums";
import { IMapping } from "../../../zod";

const commonFieldMappingProps = {
  appFieldName: "field_name",
  fieldLabel: "Field name",
  isSearchable: true,
};

const commonObjectTypeMappingProps = {
  applicationObjectTypeName: "type_name",
  applicationObjectTypeLabel: "Type name",
  status: EObjectTypeMappingStatuses.MAPPED,
};

export const mockMapping = {
  appId: EAppId.TE_REGISTRATION,
  objectTypes: [
    {
      ...commonObjectTypeMappingProps,
      applicationObjectTypeGroup: EApplicationObjectTypeGroup.COURSE,
      objectTypeId: 201,
      fields: [
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.LABEL,
          fieldId: 101,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.TITLE,
          fieldId: 102,
        },
      ],
    },
    {
      ...commonObjectTypeMappingProps,
      applicationObjectTypeGroup: EApplicationObjectTypeGroup.STUDENT_SET_GROUP,
      objectTypeId: 203,
      fields: [
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.LABEL,
          fieldId: 106,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.MAX_STUDENTS,
          fieldId: 109,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.ACTIVITY_TYPE,
          fieldId: 111,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.STATE,
          fieldId: 112,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.START_DATE,
          fieldId: 113,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.END_DATE,
          fieldId: 114,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.ALLOW_REGISTRATION,
          fieldId: 115,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.BUFFER,
          fieldId: 116,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.DEDICATETRACK,
          fieldId: 117,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.HIDETRACK,
          fieldId: 118,
        },
        {
          ...commonFieldMappingProps,
          appProperty: EAppProperties.TRACK,
          fieldId: 119,
        },
      ],
    },
    {
      ...commonObjectTypeMappingProps,
      applicationObjectTypeGroup: EApplicationObjectTypeGroup.STUDENT,
      objectTypeId: 220,
      fields: [],
    },
  ],
} satisfies IMapping;
