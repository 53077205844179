import { RightOutlined } from "@ant-design/icons";
import styles from "./courseList.module.scss";
import {
  RegistrationRequiredTranslations,
  Text,
  Title,
  createEvents,
} from "@timeedit/registration-components";
import React, {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Empty, List, Segmented } from "antd";
import { SignalBadge } from "../../SignalBadge";
import { ConflictNotification } from "../../Conflict";
import {
  IdContext,
  RegistrationContext,
  UtilContext,
} from "../RegistrationContexts";
import {
  AllocationSignal,
  Registration,
  RegistrationTrackList,
  DateInfo,
  IntRange,
  Mapping,
  MappingShorthandField,
  RegistrationMode,
  UnsafeRecord,
  isDefined,
  presentDateAndTime,
  stringHasContent,
  translations,
  parseDate,
} from "@timeedit/registration-shared";
import dayjs from "dayjs";
import { uniq } from "lodash";
import { makeTrackListRefs } from "./courseList.helpers";

const { Item } = List;

type SortType = "Course" | "Class type";
const showSortBy = false;

export function CourseList() {
  const { registration } = useContext(RegistrationContext);
  const { translations, mapping } = useContext(UtilContext);
  const { setId, id } = useContext(IdContext);
  const [sortType, setSortType] = useState<SortType>("Course");
  const itemRefs = makeTrackListRefs(registration);

  useEffect(() => {
    const ref = itemRefs[id];
    if (!isDefined(ref)) return;

    // Just scroll once we start the application
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortBy = showSortBy && (
    <>
      {translations.sortBy}:{" "}
      <Segmented<SortType>
        options={[
          {
            value: "Course",
            title: "Course",
            icon: <div>{translations.COURSE}</div>, // TODO Replace with useNames() typename
          },
          {
            value: "Class type",
            title: "Class type",
            icon: <div>{translations.classType}</div>, // TODO Replace with useNames() fieldname
          },
        ]}
        onChange={(value) => setSortType(value)}
      />
    </>
  );

  return (
    <>
      {sortBy}
      <div className={styles.courseListContainer}>
        {Object.keys(registration.courses).length === 0 ? (
          <Empty
            className={styles.emptyMessage}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={translations.noEnrolledCourses}
          />
        ) : (
          <CourseOrClassList
            sortType={sortType}
            mapping={mapping}
            registration={registration}
          />
        )}
      </div>
    </>
  );

  type CourseOrClassList = {
    sortType: SortType;
    mapping: Mapping;
    registration: Registration;
  };
  function CourseOrClassList({ sortType }: CourseOrClassList) {
    return useMemo(
      () =>
        sortType === "Course"
          ? courseList({ mapping, registration })
          : classTypeList({ mapping, registration }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [sortType, mapping, registration]
    );
  }

  type CourseListProps = {
    registration: Registration;
    mapping: Mapping;
  };
  function courseList({ mapping, registration }: CourseListProps) {
    return Object.values(registration.courses)
      .filter(isDefined)
      .map((course) => {
        const label = mapping.parse("courseLabel", course?.teObject);
        const title = mapping.parse("courseTitle", course?.teObject);

        const semester = mapping.parse("semester", course?.teObject);

        const trackLists = Object.values(registration.trackLists)
          .filter((trackList) => trackList?.parentId === course?.id)
          .filter(isDefined);

        const closeDate = uniq(
          trackLists
            .map((trackList) =>
              getSharedDate({
                mapping,
                registration,
                trackList,
                shortHand: "endDate",
              })
            )
            .filter(stringHasContent)
        );

        const startDate = uniq(
          trackLists
            .map((trackList) =>
              getSharedDate({
                mapping,
                registration,
                trackList,
                shortHand: "startDate",
              })
            )
            .filter(stringHasContent)
        );

        const oneCloseDate = closeDate.length == 1;

        const closeDateText =
          oneCloseDate && isDefined(closeDate[0])
            ? presentEndDate(
                translations,
                closeDate[0],
                startDate[0],
                registration.dateTime
              )
            : "";

        return (
          <List
            key={`list-${title}-${label}-${semester}`}
            className={styles.courseList}
            header={
              <CourseListHeader
                courseId={course.id}
                label={label}
                title={title}
                semester={semester}
                closeDateText={closeDateText}
              />
            }
            bordered
            dataSource={trackLists}
            renderItem={(trackList) => (
              <CourseListItem
                itemRef={itemRefs[trackList.id]}
                onClick={() => handleAllocationObjectClick(trackList.id)}
                hasConflict={hasTrackListConflict({ registration, trackList })}
                title={trackList.label}
                open={trackList.open}
                signal={trackList.allocationSignal}
                trackListId={trackList.id}
                courseListTranslations={translations}
              />
            )}
          />
        );
      });
  }
  function handleAllocationObjectClick(id: number) {
    setId(id.toString());
  }

  type TrackListWithCourseInfo = {
    trackList: RegistrationTrackList;
    courseLabel: string;
    title: string;
    semester: string;
    closeDate: string;
  };
  type ClassTypeListProps = {
    registration: Registration;
    mapping: Mapping;
  };
  function classTypeList({ mapping, registration }: ClassTypeListProps) {
    const trackListsWithCourseInfo = Object.values(registration.trackLists)
      .filter(isDefined)
      .reduce<UnsafeRecord<string, TrackListWithCourseInfo[]>>(
        (obj, trackList) => {
          const trackListObjToPush = trackList;

          if (!isDefined(obj[trackList.label])) obj[trackList.label] = [];

          const courseLabel = mapping.parse(
            "courseLabel",
            registration.courses[trackList.parentId]?.teObject
          );
          const title = mapping.parse(
            "courseTitle",
            registration.courses[trackList.parentId]?.teObject
          );

          const semester = mapping.parse(
            "semester",
            registration.courses[trackList.parentId]?.teObject
          );

          const closeDate = mapping.parse(
            "endDate",
            registration.courses[trackList.parentId]?.teObject
          );

          obj[trackList.label]?.push({
            trackList: trackListObjToPush,
            courseLabel,
            title,
            semester,
            closeDate,
          });

          return obj;
        },
        {}
      );

    return Object.entries(trackListsWithCourseInfo).map(
      ([key, trackListsWithCourseInfo]) => {
        return (
          <List
            key={`list-${key}`}
            className={styles.courseList}
            header={
              <CourseListHeader
                label={""}
                title={key}
                semester={""}
                closeDateText={""}
              />
            }
            bordered
            dataSource={trackListsWithCourseInfo}
            renderItem={(trackListWithCourseInfo) => (
              <CourseListItem
                itemRef={itemRefs[trackListWithCourseInfo.trackList.id]}
                onClick={() =>
                  handleAllocationObjectClick(
                    trackListWithCourseInfo.trackList.id
                  )
                }
                hasConflict={hasTrackListConflict({
                  registration,
                  trackList: trackListWithCourseInfo.trackList,
                })}
                title={
                  <div>
                    <Text strong>{trackListWithCourseInfo.title}</Text>
                    <br />
                    {trackListWithCourseInfo.courseLabel}{" "}
                    {trackListWithCourseInfo.semester}
                  </div>
                }
                signal={trackListWithCourseInfo.trackList.allocationSignal}
                trackListId={trackListWithCourseInfo.trackList.id}
                open={trackListWithCourseInfo.trackList.open}
                courseListTranslations={translations}
              />
            )}
          />
        );
      }
    );
  }
}

type CourseListHeaderProps = {
  label: string;
  courseId?: number;
  title: string;
  semester: string;
  closeDateText: string;
};

export function CourseListHeader(props: CourseListHeaderProps) {
  return (
    <div data-course-id={props.courseId}>
      <div className={styles.listHeaderContainer}>
        <Title level={5} className={styles.listHeaderName}>
          {props.title}
        </Title>
        {props.label}
      </div>
      <div className={styles.listHeaderContainer}>
        <Text>{props.closeDateText}</Text>
        {props.semester}
      </div>
    </div>
  );
}

interface CourseListItem {
  trackListId: number;
  title: JSX.Element | string;
  open?: boolean;
  signal: AllocationSignal;
  hasConflict: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  courseListTranslations: {
    registered: string;
    notRegistered: string;
  };
  itemRef?: RefObject<HTMLDivElement>;
}
export function CourseListItem({
  trackListId,
  title,
  signal,
  hasConflict,
  onClick,
  courseListTranslations,
  itemRef = React.createRef<HTMLDivElement>(),
}: CourseListItem) {
  const { id } = useContext(IdContext);
  const { registration, mode } = useContext(RegistrationContext);

  useEffect(() => {
    // To see which item is selected
    if (id === trackListId) {
      itemRef?.current?.classList.add(styles.listItemSelected);
    } else {
      itemRef?.current?.classList.remove(styles.listItemSelected);
    }
  }, [id, itemRef, trackListId]);

  return (
    <Item
      data-tracklist-id={trackListId}
      ref={itemRef}
      className={styles.listItem}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <div className={styles.listItemLeft}>
        {typeof title === "string" ? <Text strong>{title}</Text> : title}
      </div>

      <div className={styles.flexRow}>
        <div className={styles.listItemRight}>
          <SignalBadge signal={signal} translations={courseListTranslations} />
          <DescriptionText
            mode={mode}
            registration={registration}
            trackListId={trackListId}
          />
          {hasConflict && <ConflictNotification useText />}
        </div>
        <RightOutlined className={styles.listItemIcon} />
      </div>
    </Item>
  );
}

type DescriptionTextProps = {
  registration: Registration;
  mode: RegistrationMode;
  trackListId: number;
};

function DescriptionText({
  registration,
  trackListId,
  mode,
}: DescriptionTextProps) {
  const trackList = registration.trackLists[trackListId];
  if (trackList?.allocationSignal === "OK") {
    const registeredTrackId = trackList.children.find(
      (trackId) =>
        registration.tracks[trackId]?.allocationStatus === "ALLOCATED_TO_THIS"
    );
    if (!isDefined(registeredTrackId)) return "-";

    // We already createEvents in RegistrationCalendar and AllocationObjectsPage, perhaps reuse calculation here in those components?
    const reservationResults = Object.values(registration.events ?? {}).filter(
      isDefined
    );
    const reservations = reservationResults.filter(
      (event) => trackList?.children.includes(event.trackId)
    );
    const events = createEvents({ reservations });

    const eventDates = events
      .reduce<[Date, Date][]>((eventDates, event) => {
        if (event.data.allocationObjectId !== registeredTrackId)
          return eventDates;
        const currentStart = event.start;
        const currentEnd = event.end;
        const sameTimeEachWeek = eventDates.some(
          ([start, end]) =>
            dayjs(start).day() === dayjs(currentStart).day() &&
            dayjs(end).day() === dayjs(currentEnd).day() &&
            dayjs(start).hour() === dayjs(currentStart).hour() &&
            dayjs(end).hour() === dayjs(currentEnd).hour() &&
            dayjs(start).minute() === dayjs(currentStart).minute() &&
            dayjs(end).minute() === dayjs(currentEnd).minute()
        );
        if (sameTimeEachWeek) return eventDates;

        return [...eventDates, [currentStart, currentEnd]];
      }, [])
      .sort((a, b) => {
        const daySort = dayjs(a[0]).day() - dayjs(b[0]).day();
        if (daySort === 0) {
          return dayjs(a[0]).hour() - dayjs(b[0]).hour();
        }
        return daySort;
      });

    return (
      <>
        {...eventDates.map((event, index) => {
          return (
            <div
              key={`${trackListId}-${event[0].getDay()}-${index}`}
              style={{ textWrap: "nowrap" }}
            >
              {dayjs(event[0]).format("dddd HH:mm")} -{" "}
              {dayjs(event[1]).format("HH:mm")}
            </div>
          );
        })}
      </>
    );
  }

  const optionAvailable =
    trackList?.children.filter((trackId) => {
      const track = registration.tracks[trackId];
      if (!isDefined(track)) return false;
      const full =
        mode === "student"
          ? track.seats.available === 0
          : track.seats.raw.total - track.seats.raw.taken <= 0;
      return !full && track.open;
    }) ?? [];

  const openOptions =
    optionAvailable.length == 1
      ? `${translations.optionsAvailableOne} `
      : `${optionAvailable.length} ${translations.optionsAvailable} `;

  const closedOptions =
    trackList?.children.length == 0
      ? translations.optionsAvailableNone
      : translations.closed;

  return trackList?.open ? openOptions : closedOptions;
}

type HasTrackListConflictProps = {
  registration: Registration;
  trackList: RegistrationTrackList;
};

export function hasTrackListConflict({
  registration,
  trackList,
}: HasTrackListConflictProps) {
  return registration.conflicts.some((conflict) => {
    if (conflict.kind === "ALLOCATION") {
      return trackList.children.some((trackId) =>
        conflict.tracks.some((conflictTrack) => conflictTrack === trackId)
      );
    }
    return false;
  });
}

type CloseDateProps = {
  trackList?: RegistrationTrackList;
  registration: Registration;
  mapping: Mapping;
  shortHand: Extract<MappingShorthandField, "startDate" | "endDate">;
};

/**
 * @returns The date if all the tracks has the same date.
 */
export function getSharedDate({
  mapping,
  registration,
  trackList,
  shortHand,
}: CloseDateProps): string {
  let result = undefined;
  if (!isDefined(trackList)) return "";
  for (const id of trackList.children) {
    const track = registration.tracks[id];
    if (!isDefined(track)) continue;
    const date = mapping.string(shortHand, track.teObject);
    if (!isDefined(result)) {
      result = date;
      continue;
    }
    if (result !== date) {
      return "";
    }
  }
  return result || "";
}

function presentEndDate(
  translations: RegistrationRequiredTranslations,
  closeDate: string,
  openDate?: string,
  currentDateTime?: DateInfo
) {
  const end = presentDateAndTime(closeDate);
  const timezone = currentDateTime?.timezone ?? "";
  const rangeBegin = parseDate(openDate, timezone);
  if (!rangeBegin.success) {
    return `${closeDate}`;
  }
  const rangeEnd = parseDate(closeDate, timezone);
  if (!rangeEnd.success) {
    return `${closeDate}`;
  }
  const range = new IntRange(rangeBegin.data.unix, rangeEnd.data.unix);
  const now = currentDateTime?.unix ?? 0;
  if (!isDefined(now) || !isDefined(openDate) || !range.isValid()) {
    return `${translations.registrationOpenUntil} ${end}`;
  }
  if (range.isBefore(now)) {
    return `${translations.registrationOpens} ${presentDateAndTime(openDate)}`;
  }
  if (range.includes(now)) {
    return `${translations.registrationOpenUntil} ${end}`;
  }
  if (range.isAfter(now)) {
    return `${translations.registrationClosed} ${end}`;
  }
  return `${closeDate}`;
}
