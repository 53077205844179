import { toTitleCase } from "@timeedit/registration-shared";
import { HeaderProps } from "react-big-calendar";

export function CalendarHeader(props: HeaderProps) {
  const { localizer, date } = props;
  return (
    <div className="rbc-header">
      <span role="columnheader" aria-sort="none">
        {formatHeaders(date)}
      </span>
    </div>
  );

  function formatHeaders(date: Date) {
    return toTitleCase(localizer.format(date, "dddd"));
  }
}
