import {
  AllocationGroupState,
  AllowRegistrationStatus,
  DedicatedCategory,
  DedicatedRelation,
  TTEObject,
  allocationGroupStates,
  allowRegistrationStatuses,
} from "../zod";
import { isArray, isDefined, isNumber, isRecord, isString } from "./primitives";

export function isObjectWithFields(
  item: unknown
): item is Pick<TTEObject, "fields"> {
  return (
    isRecord(item) &&
    "fields" in item &&
    isArray(item.fields) &&
    item.fields.every((field: unknown) => isRecord(field) && "fieldId" in field)
  );
}

export function isAllowRegistrationStatus(
  item: unknown
): item is AllowRegistrationStatus {
  if (!isString(item)) {
    return false;
  }
  return allowRegistrationStatuses.includes(item as AllowRegistrationStatus);
}

export function isAllocationGroupState(
  item: unknown
): item is AllocationGroupState {
  if (!isString(item)) {
    return false;
  }
  return allocationGroupStates.includes(item as AllocationGroupState);
}

export function isDedicatedCategory(
  value: unknown
): value is DedicatedCategory {
  const dedicatedCategory = value as DedicatedCategory;
  if (typeof value !== "object" || !isDefined(value)) return false;
  return (
    "id" in dedicatedCategory &&
    "value" in dedicatedCategory &&
    "seats" in dedicatedCategory &&
    isNumber(dedicatedCategory.id) &&
    isString(dedicatedCategory.value) &&
    isNumber(dedicatedCategory.seats)
  );
}

export function isDedicatedRelation(
  value: unknown
): value is DedicatedRelation {
  const dedicatedRelation = value as DedicatedRelation;

  if (typeof value !== "object" || !isDefined(value)) return false;

  return (
    "id" in dedicatedRelation &&
    "seats" in dedicatedRelation &&
    !("value" in dedicatedRelation) &&
    isNumber(dedicatedRelation.id) &&
    isNumber(dedicatedRelation.seats)
  );
}
