import { ColumnType } from "antd/es/table";
import {
  NavigateAction,
  ViewProps,
  ViewStatic,
  TitleOptions as RBCTitleOptions,
} from "react-big-calendar";
import { ComponentProps, ComponentType } from "react";
import { ExpandableComponent, TimeColumn } from "./ListViewColumns";
import dayjs from "dayjs";
import { Table } from "@timeedit/registration-components";
import {
  Registration,
  RegistrationCourse,
  RegistrationTrackList,
  RegistrationTrack,
  Mapping,
  isDefined,
} from "@timeedit/registration-shared";
import { ExpandableConfig } from "antd/es/table/interface";
import { CalendarEvent } from "../../../../utils/events";
import { RegistrationRequiredTranslations } from "../../types";

type ListViewProps = {
  events: CalendarEvent[];
  registration: Registration;
  mapping: Mapping;
  translations: RegistrationRequiredTranslations;
};
export function ListView({
  events = [],
  registration,
  mapping,
  translations,
}: ListViewProps) {
  const dataSource = createDataSource({
    events,
    registration,
  });

  return (
    <div>
      {createTable({
        size: "middle",
        tableLayout: "auto",
        dataSource,
        showHeader: false,
        columns: createColumns(translations, mapping),
        pagination: false,
        expandable: tableExpandable({ mapping, registration, translations }),
      })}
    </div>
  );
}

type ScheduleListViewProps = ViewProps<CalendarEvent> & ListViewProps;

function ScheduleListView(props: ScheduleListViewProps) {
  return <ListView {...props} />;
}
interface CreateTableProps<T extends object>
  extends Omit<ComponentProps<typeof Table<T>>, "dataSource" | "columns"> {
  dataSource: T[];
  columns: ColumnType<T>[];
}

export type ScheduleListDataSource = {
  course: RegistrationCourse;
  trackList: RegistrationTrackList;
  track: RegistrationTrack;
  event: CalendarEvent;
  key: string;
};

function createTable({
  columns,
  dataSource,
  ...props
}: CreateTableProps<ScheduleListDataSource>) {
  return <Table {...props} columns={columns} dataSource={dataSource} />;
}

interface CreateDataSource {
  registration: Registration;
  events: CalendarEvent[];
}

function createDataSource({ registration, events }: CreateDataSource) {
  return events.reduce((dataSource: ScheduleListDataSource[], event, index) => {
    const track = registration.tracks[event.data.allocationObjectId];
    if (!isDefined(track)) return dataSource;

    const trackList = registration.trackLists[track.parentId];
    if (!isDefined(trackList)) return dataSource;
    const course = registration.courses[trackList.parentId];
    if (!isDefined(course)) return dataSource;

    dataSource.push({
      trackList,
      track,
      course,
      event,
      key: `${track.id.toString()} - ${index}`,
    });
    return dataSource;
  }, []);
}
type TableExpandableProps = {
  registration: Registration;
  mapping: Mapping;
  translations: RegistrationRequiredTranslations;
};
function tableExpandable(
  props: TableExpandableProps
): ExpandableConfig<ScheduleListDataSource> {
  return {
    expandedRowRender: ({ event }: ScheduleListDataSource) => {
      return <ExpandableComponent {...props} event={event} />;
    },
  };
}

ScheduleListView.navigate = (
  date: Date,
  action: NavigateAction,
  props: unknown
): Date => {
  const parameters = { date, action, props };
  return parameters.date;
};

interface TitleOptions extends Partial<RBCTitleOptions> {
  localizer: ViewProps["localizer"];
  length: ViewProps["length"];
}

ScheduleListView.title = (
  date: Date,
  { localizer, length }: TitleOptions
): string => {
  const end = localizer.add(date, length, "day");
  return localizer.format({ date, end }, "agendaHeaderFormat");
};

function createColumns(
  translations: RegistrationRequiredTranslations,
  mapping: Mapping
) {
  const columns: ColumnType<ScheduleListDataSource>[] = [
    {
      title: translations.COURSE,
      dataIndex: "course",
      key: "course",
      render: (_, { course }) => {
        const label = mapping.parse("courseLabel", course.teObject);
        const title = mapping.parse("courseTitle", course.teObject);
        return (
          <div>
            {title} - {label}
          </div>
        );
      },
    },
    {
      title: translations.time,
      dataIndex: "time",
      key: "time",
      render: (_, { event }) => (
        <TimeColumn events={[event]} translations={translations} />
      ),
      sorter: {
        multiple: 1,
        compare: (a, b) =>
          dayjs(a.event.start).hour() - dayjs(b.event.start).hour(),
      },
    },
  ];
  return columns;
}

// eslint-disable-next-line import/no-unused-modules
export default ScheduleListView as ComponentType<ViewProps<CalendarEvent>> &
  ViewStatic;
