import { Typography } from "antd";
import { LinkProps as _AntdLinkProps } from "antd/lib/typography/Link";
import styles from "./link.module.scss";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import cn from "classnames";

const { Link: AntdLink } = Typography;

interface AntdLinkProps extends _AntdLinkProps {
  to?: undefined;
}

export type LinkProps = AntdLinkProps | RouterLinkProps;

export function Link(props: LinkProps) {
  if (props.to === undefined) {
    return <AntdLink {...props}>{props.children}</AntdLink>;
  } else {
    return (
      <RouterLink {...props} className={cn(styles.link, props.className)}>
        {props.children}
      </RouterLink>
    );
  }
}
