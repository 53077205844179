import { Title } from "../Typography/Title";
import { Text } from "../Typography/Text";
import { AllocationObjectsPage } from "./AllocationObjectPage/AllocationObjectsPage";
import { Layout } from "antd";
import { CourseList } from "./CourseList";
import { useMemo, useContext } from "react";
import { SignalsPayload } from "@timeedit/registration-shared";
import { SignalSummary } from "../SignalSummary";
import { RegistrationContext, UtilContext } from "./RegistrationContexts";
import "./registrationPage.scss";

interface RegistrationDesktopProps {
  signal: SignalsPayload;
}
export function RegistrationDesktop({ signal }: RegistrationDesktopProps) {
  const { translations } = useContext(UtilContext);
  const { registration } = useContext(RegistrationContext);

  const hasConflicts = useMemo(
    () =>
      registration.conflicts.some(
        (conflicts) => conflicts.kind === "ALLOCATION"
      ),
    [registration.conflicts]
  );
  return (
    <Layout>
      <div className="registration-page__header">
        <Title level={3} className="registration-page__title">
          {translations.registration}
          <br />
          <Text type="secondary">{translations.registrationDescription}</Text>
        </Title>
        <div className="registration-page__status-container">
          <SignalSummary
            signal={signal}
            translations={translations}
            hasConflicts={hasConflicts}
          />
        </div>
      </div>

      <div className="registration-page__content">
        <section className="registration-page__course-activities">
          <CourseList />
        </section>
        <section className="registration-page__track-lists">
          <AllocationObjectsPage />
        </section>
      </div>
    </Layout>
  );
}
