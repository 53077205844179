import {
  EAppProperties,
  EApplicationObjectTypeGroup,
} from "@timeedit/types/lib/enums";

const systemRoles: Record<EApplicationObjectTypeGroup, string> = {
  [EApplicationObjectTypeGroup.NONE]: "None",
  [EApplicationObjectTypeGroup.ROOM]: "Room",
  [EApplicationObjectTypeGroup.PROFESSOR]: "Professor",
  [EApplicationObjectTypeGroup.COURSE]: "Course",
  [EApplicationObjectTypeGroup.COURSE_INFORMATION]: "Course Information",
  [EApplicationObjectTypeGroup.INVOICING]: "Invoicing",
  [EApplicationObjectTypeGroup.STUDENT_SET]: "Student Set",
  [EApplicationObjectTypeGroup.STUDENT_SET_GROUP]: "Student Set Group",
  [EApplicationObjectTypeGroup.PROGRAM]: "Program",
  [EApplicationObjectTypeGroup.ACTIVITY_TYPE]: "Activity Type",
  [EApplicationObjectTypeGroup.STUDENT]: "Student",
  [EApplicationObjectTypeGroup.STAFF]: "Staff",
  [EApplicationObjectTypeGroup.CHANGE_REQUEST]: "Change Request",
};

const columnNames: Record<EAppProperties, string> = {
  [EAppProperties.NONE]: "None",
  [EAppProperties.NAME]: "Name",
  [EAppProperties.LABEL]: "Label",
  [EAppProperties.EMAIL]: "Email",
  [EAppProperties.FIRST_NAME]: "First Name",
  [EAppProperties.LAST_NAME]: "Last Name",
  [EAppProperties.TITLE]: "Title",
  [EAppProperties.ID]: "Id",
  [EAppProperties.STATE]: "State",
  [EAppProperties.SEMESTER]: "Semester",
  [EAppProperties.MAX_STUDENTS]: "Max class size",
  [EAppProperties.WAITLIST]: "Waitlist",
  [EAppProperties.ACTIVITY_TYPE]: "Activity Type",
  [EAppProperties.START_DATE]: "Start Date",
  [EAppProperties.END_DATE]: "End Date",
  [EAppProperties.ALLOW_REGISTRATION]: "Allow Registration",
  [EAppProperties.AUTO_ALLOCATION_ACTIVATED]: "Auto Allocation Activated",
  [EAppProperties.BUFFER]: "Buffer",
  [EAppProperties.LINKTRACK]: "Linked",
  [EAppProperties.HIDETRACK]: "Hidden",
  [EAppProperties.DEDICATETRACK]: "Dedicated",
  [EAppProperties.TRACK]: "Track",
  [EAppProperties.SEATS]: "Capacity",
  [EAppProperties.DISTRIBUTION_RULE]: "Distribution Rule",
  [EAppProperties.COURSE_OCCURENCE]: "Course Occurence",
  [EAppProperties.PLANNED_SIZE]: "Planned Size",
  [EAppProperties.REGISTRATION_PERIOD_SET]: "Registration Period Set",
  [EAppProperties.PROGRAM]: "Program",
};

export const en = {
  action: "Action",
  actionNeeded: "Action needed",
  addRow: "Add Row",
  admin: "Admin",
  allocation: "Allocation",
  allocated: "Allocated",
  allocationObjects: "Allocation Objects",
  chooseFilters: "Choose filters",
  chooseSettings: "Choose Settings",
  clash: "Schedule clash",
  clashMessage: "There is a conflict between the following registrations",
  clearFilter: "Clear filters",
  closed: "Closed",
  closedBecause: "Closed Because",
  create: "Create",
  day: "Day",
  delete: "Delete",
  deleteSelected: "Delete Selected",
  pendingChangeRequests: "Pending change requests",
  done: "Done",
  duration: "Duration",
  editSettings: "Edit Settings",
  registration: "Registration",
  registrationInfo: "Registration",
  filter: "Filter",
  forcedClose: "Forced close",
  home: "Home",
  lastRegistrationDate: "Last date for registration",
  loading: "Loading...",
  logout: "Logout",
  multipleAllocations: "Multiple allocations",
  multipleMessage: "The following activity has multiple allocated tracks",
  navigate: "Navigate to",
  newChanges: "new changes",
  noAllocationGroups: "No Allocation Groups",
  noEnrolledCourses: "No Enrolled Courses",
  noNotifications: "No notifications",
  notAllocated: "Not Allocated",
  notRegistered: "Not Registered",
  noticeText: "Notices",
  ok: "Ok",
  registered: "Registered",
  register: "Register",
  registrationOpenUntil: "Registration open until",
  registrationClosed: "Registration closed",
  registrationOpens: "Registration opens",
  registrationDescription: "",
  registrationIs: "Registration period is",
  registrationOpen: "Registration is open for",
  relation: "Relation",
  rename: "Rename",
  restoreNotifications: "Restore closed notifications",
  retry: "Retry",
  pleaseTryAgain: "Please try again",
  errorInRegistration: "Error in registration",
  schedule: "Schedule",
  seats: "Seats",
  select: "Select",
  setDefault: "Set as Default",
  setTypeWarning:
    "You have fields mapped on this type. Remove them before switching.",
  settings: "Settings",
  submit: "Submit",
  swap: "Swap",
  time: "Time",
  week: "Week",
  weekAbbreviation: "w",
  welcomeTo: "Welcome to",
  welcome: "Welcome",
  registerToClasses: "Register to the classes that fit your preferences",
  youHave: "You have",
  conflict: "Conflict",
  noSeatsLeft: "No seats left",
  deallocateConfirm: "Are you sure you want to deregister?",
  deallocate: "Deregister",
  optionsAvailable: "options available",
  optionsAvailableOne: "1 option available",
  optionsAvailableNone: "No options available",
  classType: "Class Type",
  sortBy: "Sort by",
  close: "Close",
  textview: "List",
  graphicalview: "Calendar",
  teServerDown:
    "There appears to be a problem connecting to the server. Try again in a moment",
  doubleBookedErrorTitle: "Already registered on this activity",
  doubleBookedErrorText:
    "Please deregister from this activity before selecting a different option",
  sizeErrorTitle: "Option is full",
  sizeErrorText: "Please select another one",
  dedicatedErrorTitle: "Dedicated option",
  dedicatedErrorText:
    "This option is dedicated to another student group. Please select another one",
  conflictErrorTitle: "There is a conflict",
  conflictErrorText: "Please select a conflict free option",
  highDemandErrorTitle: "High demand for registration",
  hiddenErrorTitle: "Option is hidden",
  hiddenErrorText: "Please select another one",
  ...systemRoles,
  ...columnNames,
};
