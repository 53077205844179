import { AllocationBuffer } from "../../zod";
import { finiteIntegerParser, stringParser } from "./common";

export function bufferParser(value: unknown): AllocationBuffer {
  const stringValue = stringParser(value);

  const size = finiteIntegerParser(stringValue.replaceAll("%", ""));
  const isPercent = stringValue.includes("%");
  return { size, isPercent };
}
