import {
  CatalogTrackList,
  CatalogTrack,
  Catalog,
  CatalogCourse,
  RegistrationTrackList,
  RegistrationTrack,
  RegistrationCourse,
  Registration,
} from "@timeedit/registration-shared";
import { EPersonalInformation, EProperty } from "@timeedit/types/lib/enums";

const teObject = {
  id: 2,
  extId: "Course 2",
  active: true,
  abstract: true,
  fields: [
    {
      fieldId: 101,
      values: ["Course 2"],
    },
  ],
  relations: [
    {
      start: 0,
      end: 0,
      objectIds: [4, 5, 6, 7],
    },
  ],
  members: [],
  types: [201],
  property: EProperty.STANDARD,
  personalInformation: EPersonalInformation.STANDARD,
  created: 10000000,
  createdBy: "",
  history: [],
  availabilityRelations: [],
  optionalRelations: [],
  organizations: [],
  color: "",
  virtual: true,
};

export const exampleCatalogTrackParams = {
  id: 4,
  extId: "4",
  typeId: 100,
  parentId: 7,
  label: "Track 4",
  links: [],
  teObject: {
    ...teObject,
    fields: [
      {
        fieldId: 106,
        values: ["Exam"],
      },
    ],
    id: 4,
    extId: "4",
  },
} satisfies CatalogTrack;

export const exampleRegistrationTrackParams = {
  ...exampleCatalogTrackParams,
  allocationStatus: "NOT_ALLOCATED",
  seats: {
    taken: 0,
    available: 5,
    total: 0,
    raw: {
      buffer: 0,
      taken: 0,
      available: 5,
      total: 0,
    },
  },
  open: false,
  included: true,
  dedicated: {
    kind: "none",
    data: undefined,
  },
  hidden: false,
} satisfies RegistrationTrack;

export const exampleCatalogTrackListParams = {
  id: 7,
  label: "Lesson",
  parentId: 2,
  children: [],
} satisfies CatalogTrackList;

export const exampleRegistrationTrackListParams = {
  ...exampleCatalogTrackListParams,
  state: "ADJUST",
  allocationSignal: "ISSUE",
  open: false,
  included: true,
} satisfies RegistrationTrackList;

export const exampleCatalogCourseParams = {
  id: 2,
  extId: "2",
  typeId: 50,
  label: "Science",
  teObject: {
    ...teObject,
    fields: [
      {
        fieldId: 101,
        values: ["Science"],
      },
    ],
    extId: "2",
    id: 2,
  },
  children: [7],
} satisfies CatalogCourse;

export const exampleRegistrationCourseParams = {
  ...exampleCatalogCourseParams,
  open: false,
  included: true,
} satisfies RegistrationCourse;

export const _mockCatalog: Registration = {
  courses: {
    2: {
      id: 2,
      extId: "2",
      typeId: 50,
      label: "Course 2",
      teObject: {
        ...teObject,
        fields: [
          {
            fieldId: 101,
            values: ["Science"],
          },
        ],
        id: 2,
        extId: "2",
      },
      children: [7],
    },
    3: {
      id: 3,
      extId: "3",
      typeId: 50,
      label: "Course 3",
      teObject: {
        ...teObject,
        fields: [
          {
            fieldId: 101,
            values: ["Math"],
          },
        ],
        id: 3,
        extId: "3",
      },
      children: [10],
    },
  },
  trackLists: {
    7: {
      id: 7,
      label: "Lesson",
      parentId: 2,
      children: [1, 5, 11, 13],
    },
    10: {
      id: 10,
      label: "Lab",
      parentId: 3,
      children: [8],
    },
  },
  tracks: {
    1: {
      ...exampleCatalogTrackParams,
      id: 1,
      extId: "1",
      typeId: 100,
      parentId: 7,
      teObject: {
        ...exampleCatalogTrackParams.teObject,
        id: 1,
        extId: "1",
      },
    },
    5: {
      ...exampleCatalogTrackParams,
      id: 5,
      extId: "5",
      typeId: 100,
      parentId: 7,
      teObject: {
        ...exampleCatalogTrackParams.teObject,
        id: 5,
        extId: "5",
      },
    },
    8: {
      ...exampleCatalogTrackParams,
      id: 8,
      extId: "8",
      typeId: 100,
      parentId: 10,
      teObject: {
        ...exampleCatalogTrackParams.teObject,
        id: 8,
        extId: "8",
      },
    },
    11: {
      ...exampleCatalogTrackParams,
      id: 11,
      extId: "11",
      parentId: 7,
      typeId: 100,
      teObject: {
        ...exampleCatalogTrackParams.teObject,
        id: 11,
        extId: "11",
      },
    },
    13: {
      ...exampleCatalogTrackParams,
      id: 13,
      extId: "13",
      typeId: 100,
      parentId: 7,
      teObject: {
        ...exampleCatalogTrackParams.teObject,
        id: 13,
        extId: "13",
      },
    },
  },
} satisfies Catalog as any;

export const _mockRegistration: Registration = {
  courses: {
    2: {
      ...exampleRegistrationCourseParams,
      id: 2,
      children: [7],
    },
    3: {
      ...exampleRegistrationCourseParams,
      id: 3,
      children: [10],
    },
  },
  trackLists: {
    7: {
      ...exampleRegistrationTrackListParams,
      id: 7,
      parentId: 2,
      children: [1, 5, 11, 13],
      state: "ADJUST",
      allocationSignal: "OK",
    },
    10: {
      ...exampleRegistrationTrackListParams,
      id: 10,
      parentId: 3,
      children: [8],
      state: "ADJUST",
      allocationSignal: "ISSUE",
    },
  },
  tracks: {
    1: {
      ...exampleRegistrationTrackParams,
      id: 1,
      parentId: 7,
      allocationStatus: "ALLOCATED_TO_THIS",
    },
    5: {
      ...exampleRegistrationTrackParams,
      id: 5,
      parentId: 7,
      allocationStatus: "NOT_ALLOCATED",
    },
    8: {
      ...exampleRegistrationTrackParams,
      id: 8,
      parentId: 10,
      allocationStatus: "NOT_ALLOCATED",
      seats: {
        taken: 0,
        available: 5,
        total: 0,
        raw: {
          taken: 0,
          available: 5,
          total: 0,
          buffer: 0,
        },
      },
    },
    11: {
      ...exampleRegistrationTrackParams,
      id: 11,
      parentId: 7,
      allocationStatus: "NOT_ALLOCATED",
      seats: {
        taken: 0,
        available: 5,
        total: 0,
        raw: {
          taken: 0,
          available: 5,
          total: 0,
          buffer: 0,
        },
      },
    },
    13: {
      ...exampleRegistrationTrackParams,
      id: 13,
      parentId: 7,
      allocationStatus: "NOT_ALLOCATED",
      seats: {
        taken: 0,
        available: 5,
        total: 0,
        raw: {
          taken: 0,
          available: 5,
          total: 0,
          buffer: 0,
        },
      },
    },
  },
  events: {},
  conflicts: [],
  timezone: "Europe/Stockholm",
  studentId: 12,
} satisfies Registration as any;
