import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Locale } from "antd/es/locale";
import { locales } from "../../../lang/locale";

interface LanguageState {
  locale: Locale;
}

const initialState: LanguageState = {
  locale: locales ? locales.en : { locale: "en-gb" },
};

export const languageSlice = createSlice({
  initialState,
  name: "languageState",
  reducers: {
    setLocale(state, action: PayloadAction<LanguageState>) {
      state.locale = action.payload.locale;
    },
  },
});

export const { setLocale } = languageSlice.actions;
