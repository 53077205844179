import dayjs from "dayjs";
import { AllocationControls } from "@timeedit/registration-components";
import {
  RegistrationTrack,
  RegistrationTrackList,
  Registration,
  Mapping,
  RegistrationMode,
  presentText,
  DedicatedTrack,
  DedicatedCategory,
  isDefined,
  TTEObject,
  TType,
  convertToString,
  IdName,
  hasType,
  DedicatedRelation,
} from "@timeedit/registration-shared";
import { CalendarEvent } from "../../../../utils/events";
import {
  PresentObject,
  RangeReduce,
  displayAllConflicts,
  presentLabel,
} from "../../utils";
import { ClosedReason } from "../ClosedReason";
import {
  RegistrationProps,
  RegistrationRequiredTranslations,
} from "../../types";
import { Tag, Tooltip } from "antd";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { LoadActionContext } from "../../RegistrationContexts";
import { uniq } from "lodash";
import {
  getAllocationObjectIds,
  getAllocationStatus,
  getDedicationData,
  isFull,
  isOpen,
} from "./ListViewColumnUtils";

type ExpandableComponentProps = {
  event: CalendarEvent;
  registration: Registration;
  translations: RegistrationRequiredTranslations;
  mapping: Mapping;
};
export function ExpandableComponent({
  event,
  registration,
  mapping,
  translations,
}: ExpandableComponentProps) {
  const load = useContext(LoadActionContext);
  const [objects, setObjects] = useState<TTEObject[]>([]);
  const [types, setTypes] = useState<TType[]>([]);

  useEffect(() => {
    load.loadTypes().then((r) => setTypes(r));
    const ids = uniq(
      event.data.reservations.flatMap(
        (r) => r.objects?.flatMap((o) => o.objectId) ?? []
      )
    );
    load.loadObjects({ ids, useCache: true }).then((o) => setObjects(o));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allobjects = useMemo(() => {
    const typeNames = uniq(objects.map((o) => o.types[0]))
      .map((id) => ({
        id,
        name: convertToString(types.find((t) => t.id === id)?.name),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    const present = presentLabel(mapping);
    const presentObjectsOfType = (type: IdName) =>
      presentObjects(
        objects.filter((o) => hasType(o, type.id)),
        type.id,
        present
      );
    return typeNames
      .map((type) => {
        const text = presentObjectsOfType(type);
        if (!isDefined(text)) {
          return undefined;
        }
        return (
          <Fragment key={type.id}>
            <div>{type.name}:</div>
            {text}
          </Fragment>
        );
      })
      .filter(isDefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, types]);

  const conflicts = displayAllConflicts({
    registration,
    event,
    mapping,
    translations,
  });

  return (
    <>
      <div>{conflicts}</div>
      <div
        className="resbox"
        data-reservation-ids={event.data.reservations.map((r) => r.id)}
      >
        <div>{translations.day}:</div>
        <DayColumn events={[event]} />
        <div>{translations.time}:</div>
        <TimeColumn events={[event]} translations={translations} />
        <div>{translations.week}:</div>
        <WeekColumn events={[event]} translations={translations} />
        {allobjects}
      </div>
      <ClosedReason
        mapping={mapping}
        translations={translations}
        style={{ display: "block" }}
        track={registration.tracks[event.data.allocationObjectId]}
      />
    </>
  );
}

const presentObjects = (
  objects: TTEObject[],
  key: number,
  present: PresentObject
) => {
  const texts = objects
    .map((o) => present(o))
    .filter((text) => text.trim().length > 0);
  if (texts.length === 0) {
    return undefined;
  }
  return (
    <div key={"oo" + key}>
      {texts.map((text, ident) => (
        <div key={"oo" + key + "tt" + ident}>{text}</div>
      ))}
    </div>
  );
};

export function DateColumn({
  events,
  autoWidth,
  translations,
}: {
  events: CalendarEvent[];
  autoWidth: boolean;
  translations: RegistrationRequiredTranslations;
}) {
  return (
    <>
      {events.map(({ start, end, data }, index) => {
        const startDate = dayjs(start).format("HH:mm");
        const endDate = dayjs(end).format("HH:mm");
        return (
          <div
            className="datecolumn"
            key={`dayweekColumn-${data.allocationObjectId}-${index}`}
          >
            <div>
              <span className={autoWidth ? "daywide" : ""}>
                {dayjs(start).format("dddd")}
              </span>
              {autoWidth && (
                <span className="daysmall">{dayjs(start).format("ddd")}</span>
              )}
            </div>
            <div className="timeColumn">
              {startDate} - {endDate}
            </div>
            <div>
              {translations.weekAbbreviation}{" "}
              {RangeReduce.pairPresent(data.reservedWeeks ?? [])}
            </div>
          </div>
        );
      }) ?? []}
    </>
  );
}

function DayColumn({ events }: { events: CalendarEvent[] }) {
  return (
    <>
      {events.map(({ start, data }, index) => {
        return (
          <div key={`dayColumn-${data.allocationObjectId}-${index}`}>
            {dayjs(start).format("dddd")}
          </div>
        );
      }) ?? []}
    </>
  );
}

export function TimeColumn({
  events,
}: {
  events: CalendarEvent[];
  translations: RegistrationRequiredTranslations;
}) {
  // TODO: Support AM and PM formats?
  return (
    <>
      {events.map(({ start, end, data }, index) => {
        const startDate = dayjs(start).format("HH:mm");
        const endDate = dayjs(end).format("HH:mm");
        return (
          <div
            key={`timeColumn-${data.allocationObjectId}-${index}`}
            className="timeColumn"
          >
            {startDate} - {endDate}
          </div>
        );
      }) ?? []}
    </>
  );
}

function WeekColumn({
  events,
  translations,
}: {
  events: CalendarEvent[];
  translations: RegistrationRequiredTranslations;
}) {
  return (
    <>
      {events.map(({ data }, index) => {
        return (
          <div key={`weekColumn-${data.allocationObjectId}-${index}`}>
            {translations.weekAbbreviation}{" "}
            {RangeReduce.pairPresent(data.reservedWeeks ?? [])}
          </div>
        );
      }) ?? []}
    </>
  );
}

interface HiddenColumnProps {
  hidden: boolean;
  label: string;
}

export function HiddenColumn({ hidden, label }: HiddenColumnProps) {
  if (!hidden) return <div style={{ width: "2rem" }}></div>;
  return (
    <Tooltip title={label}>
      <Tag>{label}</Tag>
    </Tooltip>
  );
}

interface DedicatedColumnProps {
  dedicated: DedicatedTrack[];
  presentCategory: (data: DedicatedCategory[]) => string;
  presentRelation: (data: DedicatedRelation[]) => string;
}

export function DedicatedColumn({
  dedicated,
  presentCategory,
  presentRelation,
}: DedicatedColumnProps) {
  const data = useMemo(() => getDedicationData(dedicated), [dedicated]);

  const categoryText =
    data.category.length > 0 ? presentCategory(data.category) : "";
  const relationText =
    data.relation.length > 0 ? presentRelation(data.relation) : "";

  if (relationText === "" && categoryText === "")
    return <div style={{ width: "2rem" }}></div>;

  return [categoryText, relationText].reduce(
    (textResult: JSX.Element[], text) => {
      if (text === "") return textResult;
      return [
        ...textResult,
        <Tooltip
          key={`${text}`}
          overlayStyle={{ whiteSpace: "pre-line" }}
          title={text}
        >
          <div>{presentText(text)}</div>
        </Tooltip>,
      ];
    },
    []
  );
}

interface AllocationControlColumn {
  tracks: RegistrationTrack[];
  trackList: RegistrationTrackList;
  hasConflict: boolean;
  registration: Registration;
  translations: RegistrationRequiredTranslations;
  allocationControlActions?: RegistrationProps["allocationControlActions"];
  mode?: RegistrationMode;
}
export function AllocationControlColumn({
  hasConflict,
  trackList,
  tracks,
  registration,
  translations,
  allocationControlActions,
  mode = "student",
}: AllocationControlColumn) {
  return (
    <>
      <AllocationControls
        translations={translations}
        full={isFull({ tracks, mode })}
        mode={mode}
        state={trackList.state}
        allocationStatus={getAllocationStatus(tracks)}
        loading={allocationControlActions?.isLoading}
        open={isOpen(tracks)}
        onSelect={() => {
          allocationControlActions?.onAllocate({
            prevObjectIds: getAllocationObjectIds({ registration, trackList }),
            // Allocation to a linked track will happen on the server side
            // so just allocating to one is enough.
            newObjectId: tracks[0].id,
          });
        }}
        onDelete={() => {
          allocationControlActions?.onDeallocate({
            objectIds: tracks.map((track) => track.id),
          });
        }}
        clash={hasConflict}
      />
    </>
  );
}
