import { createContext } from "react";
import {
  LoadActions,
  RegistrationProps,
  RegistrationRequiredTranslations,
} from "./types";
import {
  ChangeRequest,
  Registration,
  DedicatedCategory,
  DedicatedRelation,
  LoadBody,
  Mapping,
  MinMaxTimestamps,
  RegistrationMode,
  TField,
  TTEObject,
  TType,
} from "@timeedit/registration-shared";
import { NavigateFunction } from "react-router-dom";

type RegistrationContextType = {
  registration: Registration;
  mode: RegistrationMode;
};

export const RegistrationContext = createContext<RegistrationContextType>(
  {} as RegistrationContextType
);

type IdContextType = {
  setId: (id: string) => void;
  studentId?: number;
  id: number;
};
export const IdContext = createContext<IdContextType>({} as IdContextType);

type AllocationControlContextType =
  RegistrationProps["allocationControlActions"];
export const AllocationControlContext =
  createContext<AllocationControlContextType>(
    {} as AllocationControlContextType
  );

type UtilContextType = {
  navigate: NavigateFunction;
  translations: RegistrationRequiredTranslations;
  mapping: Mapping;
  dedicatedTracksActions: {
    presentCategory: (data: DedicatedCategory[]) => string;
    presentRelation: (data: DedicatedRelation[]) => string;
  };
};
export const UtilContext = createContext<UtilContextType>(
  {} as UtilContextType
);

type DateIntervalContextType = {
  allowedDateInterval: MinMaxTimestamps;
  dateInterval: MinMaxTimestamps;
};
export const DateIntervalContext = createContext<DateIntervalContextType>(
  {} as DateIntervalContextType
);

type ChangeRequestContextType = {
  changeRequests: ChangeRequest[];
};
export const ChangeRequestContext = createContext<ChangeRequestContextType>(
  {} as ChangeRequestContextType
);

export const LoadActionContext = createContext<LoadActions>({
  loadObjects: (_: LoadBody) => Promise.resolve<TTEObject[]>([]),
  loadTypes: () => Promise.resolve<TType[]>([]),
  loadFields: () => Promise.resolve<TField[]>([]),
});
