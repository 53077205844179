import { Badge } from "antd";
import { SignalsPayload } from "@timeedit/registration-shared";
import { ConflictNotification } from "../Conflict";
import "./signalSummary.scss";

type SignalSummaryProps = {
  signal: SignalsPayload;
  translations: {
    registered: string;
    notRegistered: string;
  };
  hasConflicts: boolean;
};
export function SignalSummary({
  signal,
  translations,
  hasConflicts,
}: SignalSummaryProps) {
  return (
    <>
      <div className="success__wrapper">
        <Badge
          status="success"
          text={`${translations.registered} ${signal.summary.OK}`}
          className="success__badge"
        />
        {hasConflicts && <ConflictNotification />}
      </div>

      <Badge
        status="error"
        text={`${translations.notRegistered} ${signal.summary.ISSUE}`}
      />
    </>
  );
}
