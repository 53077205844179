import { Typography } from "antd";
import { TitleProps as AntdTitleProps } from "antd/lib/typography/Title";

const { Title: AntdTitle } = Typography;

type TitleProps = AntdTitleProps;

export function Title(props: TitleProps) {
  return <AntdTitle {...props}>{props.children}</AntdTitle>;
}
