import { Title } from "../Typography/Title";
import { Text } from "../Typography/Text";
import { AllocationObjectsPage } from "./AllocationObjectPage/AllocationObjectsPage";
import { Layout } from "antd";
import { SignalSummary } from "../../components/SignalSummary";
import { CourseList } from "./CourseList";
import { useContext, useMemo } from "react";
import { SignalsPayload } from "@timeedit/registration-shared";
import {
  IdContext,
  RegistrationContext,
  UtilContext,
} from "./RegistrationContexts";
import "./registrationPage.scss";

interface RegistrationMobileProps {
  signal: SignalsPayload;
}
export function RegistrationMobile({ signal }: RegistrationMobileProps) {
  const { id } = useContext(IdContext);
  const { translations } = useContext(UtilContext);
  const { registration } = useContext(RegistrationContext);

  const hasConflicts = useMemo(
    () =>
      registration.conflicts.some(
        (conflicts) => conflicts.kind === "ALLOCATION"
      ),
    [registration.conflicts]
  );

  const showOutlet = useMemo(() => {
    return !!id;
  }, [id]);

  if (showOutlet) return <AllocationObjectsPage />;

  return (
    <Layout>
      <div className="registration-page__header">
        <Title level={3} className="registration-page__title">
          {translations.registration}
          <br />
          <Text type="secondary">{translations.registrationDescription}</Text>
        </Title>
        <div className="registration-page__status-container">
          <SignalSummary
            signal={signal}
            translations={translations}
            hasConflicts={hasConflicts}
          />
        </div>
      </div>
      <div className="registration-page__content">
        <section className="registration-page__course-activities">
          <CourseList />
        </section>
      </div>
    </Layout>
  );
}
