import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/Router";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // StrictMode renders components twice (on dev but not production)
  // in order to detect any problems with your code and warn you about them
  // making useEffect trigger twice

  <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </StrictMode>
);
