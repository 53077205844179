/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../baseQuery";

export const reportsApiSlice = createApi({
  reducerPath: "reportsApi",
  baseQuery,
  endpoints(builder) {
    return {
      fetchStudentRegistrationReport: builder.mutation<null, void>({
        queryFn: async (_1, _2, _3, baseQuery) => {
          const result = await baseQuery({
            url: `/reports/student-registrations`,
            responseHandler: (response) => response.blob(),
          });
          const hiddenElement = document.createElement("a");
          const url = window.URL || window.webkitURL;
          const blobCSV = url.createObjectURL(result.data as Blob);
          hiddenElement.href = blobCSV;
          hiddenElement.target = "_blank";
          hiddenElement.download = `student-registrations.csv`;
          hiddenElement.click();
          return { data: null };
        },
      }),
      fetchAnonymousStudentRegistrationReport: builder.mutation<null, void>({
        queryFn: async (_1, _2, _3, baseQuery) => {
          const result = await baseQuery({
            url: `/reports/student-registrations-anonymous`,
            responseHandler: (response) => response.blob(),
          });
          const hiddenElement = document.createElement("a");
          const url = window.URL || window.webkitURL;
          const blobCSV = url.createObjectURL(result.data as Blob);
          hiddenElement.href = blobCSV;
          hiddenElement.target = "_blank";
          hiddenElement.download = `student-registrations-anonymous.csv`;
          hiddenElement.click();
          return { data: null };
        },
      }),
      fetchCourseAllocationReport: builder.mutation<null, void>({
        queryFn: async (_1, _2, _3, baseQuery) => {
          const result = await baseQuery({
            url: `/reports/allocations`,
            responseHandler: (response) => response.blob(),
          });
          const hiddenElement = document.createElement("a");
          const url = window.URL || window.webkitURL;
          const blobCSV = url.createObjectURL(result.data as Blob);
          hiddenElement.href = blobCSV;
          hiddenElement.target = "_blank";
          hiddenElement.download = `course-allocations.csv`;
          hiddenElement.click();
          return { data: null };
        },
      }),
    };
  },
});

export const {
  useFetchCourseAllocationReportMutation,
  useFetchStudentRegistrationReportMutation,
  useFetchAnonymousStudentRegistrationReportMutation,
} = reportsApiSlice;
