/* eslint-disable no-console */
import {
  FieldKey,
  MappingConfig,
  MappingConfigEntry,
  MappingLookup,
  MappingParser,
  MappingStore,
  ObjectTypeKey,
} from "./mapping.types";
import { stringParser } from "./parsers";
import {
  isArray,
  isDefined,
  isNumber,
  isObjectWithFields,
  isRecord,
} from "../typechecks";
import { IMapping, ITypeMapping, TField, TTEObject, TType } from "../zod";

function includeFields<C>(
  store: MappingStore<C>
): (typeId: number) => number[] {
  return (typeId) => {
    if (typeId <= 0) {
      return [];
    }
    return store.mappingData?.objectTypes
      .filter((t) => t.objectTypeId === typeId)
      .flatMap((t) => t.fields)
      .map((f) => f.fieldId)
      .filter(isNumber);
  };
}

function searchableFields<C>(
  store: MappingStore<C>
): (typeId: number) => number[] {
  return (typeId) => {
    if (typeId <= 0) {
      return [];
    }
    return store.mappingData?.objectTypes
      .filter((t) => t.objectTypeId === typeId)
      .flatMap((t) => t.fields)
      .filter((f) => f.isSearchable)
      .map((f) => f.fieldId)
      .filter(isNumber);
  };
}

/**
 * @returns Empty array if the key is not mapped
 */
function getIds<C>(
  store: MappingStore<C>,
  config: MappingConfig<C>
): (
  shorthand: keyof MappingConfig<C> | MappingConfigEntry["props"]
) => number[] {
  return (shorthand) => {
    const props = getProps(config, shorthand);
    if (!isDefined(props)) {
      return [];
    }
    if (!("appProperty" in props)) {
      const objectTypeId =
        store.lookup.objectTypes?.[props.objectTypeGroup]?.objectTypeId;
      if (objectTypeId === undefined) {
        return [];
      }
      return [objectTypeId];
    }
    return (
      store.lookup.fields?.[`${props.objectTypeGroup}-${props.appProperty}`]
        ?.fieldIds ?? []
    );
  };
}

function isMappingConfigEntryProps(
  props: unknown
): props is MappingConfigEntry["props"] {
  return isRecord(props) && "objectTypeGroup" in props;
}

/**
 * Private function to get the props object from the config.
 */
function getProps<C>(
  config: MappingConfig<C>,
  shorthand: keyof MappingConfig<C> | MappingConfigEntry["props"]
): MappingConfigEntry["props"] | undefined {
  if (typeof shorthand === "string") {
    return config[shorthand]?.props;
  }
  return isMappingConfigEntryProps(shorthand) ? shorthand : undefined;
}

const NOT_MAPPED: number = 0;

function handleUnmapped(id: number | undefined): number {
  return isDefined(id) ? id : NOT_MAPPED;
}

function getId<C>(
  store: MappingStore<C>,
  config: MappingConfig<C>
): (
  shorthand?: keyof MappingConfig<C> | MappingConfigEntry["props"]
) => number {
  return (shorthand) => {
    if (!isDefined(shorthand)) {
      return NOT_MAPPED;
    }
    const props = getProps(config, shorthand);
    if (!isDefined(props)) {
      return NOT_MAPPED;
    }
    if (!("appProperty" in props)) {
      const objectTypeId =
        store.lookup.objectTypes?.[props.objectTypeGroup]?.objectTypeId;
      return handleUnmapped(objectTypeId);
    }
    const fieldId =
      store.lookup.fields?.[`${props.objectTypeGroup}-${props.appProperty}`]
        ?.fieldIds[0];
    return handleUnmapped(fieldId);
  };
}

function getKey<C>(
  config: MappingConfig<C>
): (
  shorthand: keyof MappingConfig<C> | MappingConfigEntry["props"]
) => FieldKey | ObjectTypeKey {
  return (shorthand) => {
    const props = getProps(config, shorthand);
    if (!isDefined(props)) {
      return "NONE";
    }
    if (!("appProperty" in props)) {
      return `${props.objectTypeGroup}`;
    }
    return `${props.objectTypeGroup}-${props.appProperty}`;
  };
}

function getObjectTypeKeyById<C>(
  store: MappingStore<C>
): (id: number) => ObjectTypeKey {
  return (id) => {
    const objectTypeKey = Object.values(store.lookup.objectTypes).find(
      (o) => o.objectTypeId === id
    )?.key;
    if (objectTypeKey === undefined) {
      return "NONE";
    }
    return objectTypeKey;
  };
}

function getFieldKeyById<C>(store: MappingStore<C>): (id: number) => FieldKey {
  return (id) => {
    const fieldKey = Object.values(store.lookup.fields).find((f) =>
      f.fieldIds.includes(id)
    )?.key;
    if (fieldKey === undefined) {
      return "NONE-NONE";
    }
    return fieldKey;
  };
}

function parse<C, P>(
  store: MappingStore<C>,
  config: MappingConfig<C>,
  parsers: MappingParser<P>
) {
  return <S extends keyof MappingParser<P>>(shorthand: S, input: unknown) => {
    if (isObjectWithFields(input)) {
      try {
        const fieldIds = getIds(
          store,
          config
        )(shorthand as keyof MappingConfig<C>);
        const parser = parsers[shorthand];
        return parser(values(store)(fieldIds, input)) as ReturnType<
          MappingParser<P>[S]
        >;
      } catch (e) {
        const parser = parsers[shorthand];
        return parser(undefined) as ReturnType<MappingParser<P>[S]>;
      }
    }
    const parser = parsers[shorthand];
    return parser(input) as ReturnType<MappingParser<P>[S]>;
  };
}

function values<C>(store: MappingStore<C>) {
  return (fieldIds: number[], input: Pick<TTEObject, "fields">) => {
    const findValues = (fieldId: number) => {
      const values = input.fields.find((field) => field.fieldId === fieldId)
        ?.values;
      if (isArray(values)) {
        return values;
      }
      if (isDefined(values)) {
        // Should never happen since values is supposed to be an array, but
        // this case is there in case that's not true and we want to see the
        // value that's there. Basically to satisfy some of our test cases.
        return [values as unknown as string];
      }
      return [];
    };
    const values: string[] = [];

    for (const fieldId of fieldIds) {
      const field = store.fields?.find((field) => field.id === fieldId);
      // If the field is a reference field: find the values for each
      // referenced object, parse them as strings, concatinate with any field
      // values found on the original field, and join them with the ref
      // separator, then treat the result as if it was the value for the field.
      if (field?.referenceFields.length) {
        const referenceValues = field.referenceFields.map(findValues);
        const fieldValue = findValues(field.id);
        const value = fieldValue
          .concat(referenceValues.map((v) => stringParser(v)))
          .filter((v) => !!v)
          .join(field.refSeparator ?? " ");
        values.push(value);
        continue;
      }
      const fieldValues = findValues(fieldId);
      if (isDefined(fieldValues)) {
        if (isArray(fieldValues)) {
          values.push(...fieldValues);
        } else {
          values.push(fieldValues);
        }
      }
    }

    return values;
  };
}

function stringKey<C>(store: MappingStore<C>) {
  return (key: FieldKey, input: unknown) => {
    if (isObjectWithFields(input)) {
      const fieldIds = store.lookup.fields?.[key]?.fieldIds ?? [];
      return stringParser(values(store)(fieldIds, input));
    }
    return stringParser(input);
  };
}

function string<C, P>(
  store: MappingStore<C>,
  config: MappingConfig<C>,
  _: MappingParser<P>
) {
  return <S extends keyof MappingParser<P>>(
    identifier: S | MappingConfigEntry["props"] | number,
    input: unknown
  ) => {
    if (isObjectWithFields(input)) {
      try {
        const fieldIds = isNumber(identifier)
          ? [identifier]
          : getIds(store, config)(identifier as keyof MappingConfig<C>);
        return stringParser(values(store)(fieldIds, input));
      } catch (e) {
        return stringParser(undefined);
      }
    }
    return stringParser(input);
  };
}

function isMapped<C>(
  store: MappingStore<C>,
  config: MappingConfig<C>
): (
  identifier: keyof MappingConfig<C> | MappingConfigEntry["props"] | number
) => boolean {
  return (identifier) => {
    const id = isNumber(identifier)
      ? identifier
      : getId(store, config)(identifier);
    return id !== NOT_MAPPED;
  };
}

function check<C>(store: MappingStore<C>, config: MappingConfig<C>) {
  return (
    id: number,
    shorthand: keyof MappingConfig<C> | MappingConfigEntry["props"]
  ) => {
    try {
      return getId(store, config)(shorthand) === id;
    } catch (e) {
      return false;
    }
  };
}

function typename<C>(store: MappingStore<C>, config: MappingConfig<C>) {
  return (
    identifier: keyof MappingConfig<C> | MappingConfigEntry["props"] | number
  ) => {
    const { id } = parseIdentifier(store, config)(identifier);
    if (!isMapped(store, config)(id)) {
      return `MissingTypeMapping[${String(identifier)} (${id})]`;
    }
    if (!isDefined(store.types)) {
      console.warn(
        "Trying to use mapping.typename with mapping instance that has not been initialized with types."
      );
      return `MissingTypeName[${String(identifier)} (${id})]`;
    }
    const type = store.types.find((t) => t.id === id);
    if (!isDefined(type)) {
      return `UnknownType[${String(identifier)} (${id})]`;
    }
    return type.name;
  };
}

function fieldname<C>(store: MappingStore<C>, config: MappingConfig<C>) {
  return (
    identifier: keyof MappingConfig<C> | MappingConfigEntry["props"] | number
  ) => {
    const { id } = parseIdentifier(store, config)(identifier);
    if (!isDefined(id)) {
      return `UnknownFieldName[${String(identifier)} (${id})]`;
    }
    if (!isMapped(store, config)(id)) {
      return `MissingFieldMapping[${String(identifier)} (${id})]`;
    }
    if (!isDefined(store.fields)) {
      console.warn(
        "Trying to use mapping.fieldname with mapping instance that has not been initialized with fields."
      );
      return `MissingFieldName[${String(identifier)} (${id})]`;
    }
    const field = store.fields.find((t) => t.id === id);
    if (!isDefined(field)) {
      return `UnknownField[${String(identifier)} (${id})]`;
    }
    return field.name;
  };
}

/**
 * Private function to find a shorthand for a key created for the lookup.
 */
function findShorthand<C>(
  config: MappingConfig<C>,
  key: ObjectTypeKey | FieldKey
): keyof MappingConfig<C> | undefined {
  return Object.keys(config).find((shorthand) => config[shorthand].key === key);
}

/**
 * Private function to parse an identifier and get key and id.
 */
function parseIdentifier<C>(store: MappingStore<C>, config: MappingConfig<C>) {
  return (
    identifier: keyof MappingConfig<C> | MappingConfigEntry["props"] | number,
    kind: "type" | "field" = "field"
  ) => {
    const key = isNumber(identifier)
      ? kind === "field"
        ? getFieldKeyById(store)(identifier)
        : getObjectTypeKeyById(store)(identifier)
      : getKey(config)(identifier);
    const shorthand = isNumber(identifier)
      ? findShorthand(config, key)
      : identifier;
    const id = isNumber(identifier)
      ? identifier
      : getId(store, config)(shorthand);
    return { key, id, shorthand };
  };
}

/**
 * Private function to create a lookup table of mappings used in the utility
 * functions.
 */
function makeLookup<C>(config: MappingConfig<C>, objectTypes: ITypeMapping[]) {
  return objectTypes?.reduce(
    (mapLook: MappingLookup<C>, objectType) => {
      if (isDefined(objectType.applicationObjectTypeGroup)) {
        const objectTypeKey = objectType.applicationObjectTypeGroup;
        const objectTypeId = objectType.objectTypeId;
        mapLook.objectTypes[objectTypeKey] = {
          objectTypeId: objectTypeId === null ? undefined : objectTypeId,
          key: objectTypeKey,
          shorthand: findShorthand(config, objectTypeKey),
        };
        for (const field of objectType.fields) {
          if (isDefined(field.appProperty) && field.appProperty !== "NONE") {
            const fieldKey =
              `${objectTypeKey}-${field.appProperty}` as FieldKey;
            const fieldIds = isDefined(field.fieldId) ? [field.fieldId] : [];
            const found = mapLook.fields[fieldKey];
            if (isDefined(found)) {
              found.fieldIds = found.fieldIds.concat(fieldIds);
              continue;
            }
            mapLook.fields[fieldKey] = {
              fieldIds,
              objectTypeId: objectTypeId === null ? undefined : objectTypeId,
              objectTypeKey,
              key: fieldKey,
              shorthand: findShorthand(config, fieldKey),
            };
          }
        }
      }
      return mapLook ?? { objectTypes: {}, fields: {} };
    },
    { objectTypes: {}, fields: {} }
  );
}

interface CreateMappingUtilsProps<C, P> {
  config: MappingConfig<C>;
  parsers: MappingParser<P>;
}

export interface MappingProps {
  mappingData: IMapping;
  fields?: TField[];
  types?: TType[];
}

/**
 * Creates a customized version of the createMappingInstance for an application.
 * Use it once in a file and provide your local version of the parameters.
 * @param config - lookup table of mappings that are frequently used within the app
 * @returns a function that can be used to create a mapping instance for an organization
 */
export function createMappingUtilsForApp<
  C extends Record<string, unknown>,
  P extends Record<string, unknown>,
>({ parsers, config }: CreateMappingUtilsProps<C, P>) {
  return ({ mappingData, fields, types }: MappingProps) => {
    const store: MappingStore<C> = {
      mappingData,
      fields,
      types,
      lookup: makeLookup(config, mappingData?.objectTypes),
    };

    return {
      /**
       * @param typeId - the shorthand string key for the desired mapping.
       * You can also pass the props object from the mapping config directly.
       * @returns ids of the fields in the right order.
       */
      includeFields: includeFields(store),
      /**
       * @param typeId - the shorthand string key for the desired mapping.
       * You can also pass the props object from the mapping config directly.
       * @returns ids of the fields in the right order.
       */
      searchableFields: searchableFields(store),
      /**
       * @param identifier - the shorthand string key for the desired mapping.
       * You can also pass the props object from the mapping config directly.
       * Or an id of a type or field received from the getId method.
       * @returns true if the key is mapped.
       */
      isMapped: isMapped(store, config),
      /**
       * @param shorthand - the shorthand string key for the desired mapping.
       * You can also pass the props object from the mapping config directly.
       * @returns the id pointed to by the mapping shorthand, 0 if the the key is not mapped
       */
      getId: getId(store, config),
      /**
       * @param shorthand - the shorthand string key for the desired mapping
       * @returns the ids pointed to by the mapping shorthand, empty array is the key is not mapped
       */
      getIds: getIds(store, config),
      /**
       * @param id - the id to check
       * @param shorthand - the shorthand string key for the mapping to check.
       * You can also pass the props object from the mapping config directly.
       * @returns a boolean indicating if the id matches the mapping shorthand
       */
      check: check(store, config),

      /**
       * @param identifier - the shorthand string key for the desired mapping.
       * You can also pass the props object from the mapping config directly.
       * Or an id of a field received from the getId method.
       * @returns the name of the type.
       */
      typename: typename(store, config),

      /**
       * @param identifier - the shorthand string key for the desired mapping.
       * You can also pass the props object from the mapping config directly.
       * Or an id of a field received from the getId method.
       * @returns the name of the field.
       */
      fieldname: fieldname(store, config),

      /**
       * @param id - the id to check
       * @returns the key for the object type
       */
      getKey: getKey(config),
      /**
       * @param shorthand - the shorthand string key for the desired mapping
       * You can also pass the props object from the mapping config directly.
       * @returns the key pointed to by the mapping shorthand
       */
      getObjectTypeKeyById: getObjectTypeKeyById(store),
      /**
       * @param id - the id to check
       * @returns the key for the field
       */
      getFieldKeyById: getFieldKeyById(store),
      /**
       * @param shorthand - the shorthand string key to for the mapped value
       * Passing props directly is not allowed because only shorthands are
       * guaranteed to have corresponding parsers.
       * @param input - a TE object that you want to find out the field value of
       * OR any value that you want to parse
       * @returns a value parsed with the configured parsing function
       */
      parse: parse(store, config, parsers),
      /**
       * @param identifier - the shorthand string key to for the mapped value
       * You can also pass the props object from the mapping config directly.
       * Or an id of a field received from the getId method.
       * @param input - a TE object that you want to find out the field value of
       * OR any value that you want to parse
       * @returns a value parsed as a string
       */
      string: string(store, config, parsers),
      /**
       * @param fieldKey - the FieldKey key to for the mapped value
       * @param input - a TE object that you want to find out the field value of
       * OR any value that you want to parse
       * @returns a value parsed as a string
       */
      stringKey: stringKey(store),
      store,
    };
  };
}
